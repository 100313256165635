<script setup>
import { Link } from '@inertiajs/vue3'
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import NavMenuLanguageSwitcher from '@js/Components/NavMenu/NavMenuLanguageSwitcher.vue'
import NavMenuSupportCardList from '@js/Components/NavMenu/NavMenuSupportCardList.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { onClickLogoutUser } from '@js/App/Api/Auth'
import Container from '@js/Components/Container/Container.vue'
import { useUser } from '@js/App/Api/User/User'

defineProps({ isOpen: Boolean })
defineEmits(['close'])

const { user } = useUser()
</script>
<template>
  <div
    class="fixed top-0 left-0 w-full h-full min-h-screen overflow-auto z-50 transition-transform text-white"
    :class="{
      'translate-y-0': isOpen,
      'translate-y-[-110%]': !isOpen
    }"
  >
    <div class="w-full min-h-full bg-purple-900">
      <Container class="py-4 lg:py-8 pb-24 lg:pb-8">
        <div class="pb-4 flex justify-between items-center">
          <RentbirdLogo :href="$getLocalizedUrl('welcome')" />

          <!-- A div for the right side -->
          <div class="flex">
            <Link v-if="!user.isLoggedIn" :href="$getLocalizedUrl('login')">
              <div class="p-4 flex hover:pointer hover:text-pink-500 transition-colors">
                <Icon name="user" class="w-[24px] h-[24px] mr-3" />

                <span>{{ $trans('NavMenuFoldout.Login') }}</span>
              </div>
            </Link>
            <Link v-else :href="$getLocalizedUrl('my-rentbird.home')">
              <div class="p-4 flex hover:pointer hover:text-pink-500 transition-colors">
                <Icon name="user" class="w-[24px] h-[24px] mr-3" />

                <span>{{ $trans('NavMenuFoldout.MyRentbird') }}</span>
              </div>
            </Link>

            <div
              class="p-4 flex hover:pointer hover:text-pink-500 transition-colors"
              role="button"
              @click="$emit('close')"
            >
              <Icon name="cross" class="w-[24px] h-[24px] lg:mr-1" />

              <span class="hidden lg:block">
                {{ $trans('NavMenuFoldout.Close') }}
              </span>
            </div>
          </div>
        </div>

        <div class="lg:flex lg:justify-between">
          <div class="pt-4 pb-5 lg:pt-16 lg:flex-1 lg:max-w-[704px] lg:pr-8">
            <div class="text-2xl font-medium lg:text-4xl">
              <div class="pb-4 pt-0 border-b border-gray-400">
                <Link :href="$getLocalizedUrl('how-it-works')" class="transition-colors hover:text-pink-500">
                  {{ $trans('NavMenuFoldout.HowItWorks') }}
                </Link>
              </div>
              <div class="pb-4 pt-4 border-b border-gray-400">
                <Link :href="$getLocalizedUrl('pricing')" class="transition-colors hover:text-pink-500">
                  {{ $trans('NavMenuFoldout.Pricing') }}
                </Link>
              </div>
              <div class="pb-4 pt-4 border-b border-gray-400">
                <Link :href="$getLocalizedUrl('reviews')" class="transition-colors hover:text-pink-500">
                  {{ $trans('NavMenuFoldout.Reviews') }}
                </Link>
              </div>
              <div class="pb-4 pt-4 border-b border-gray-400">
                <Link :href="$getLocalizedUrl('faq')" class="transition-colors hover:text-pink-500">
                  {{ $trans('NavMenuFoldout.Faq') }}
                </Link>
              </div>
            </div>
            <div class="text-lg font-medium lg:text-xl pt-8 lg:pt-10">
              <div class="pb-2 pt-2 border-b border-gray-400">
                <Link :href="$getLocalizedUrl('rentbird-plus')" class="transition-colors hover:text-pink-500">
                  Rentbird Plus
                </Link>
              </div>
              <div class="pb-2 pt-2 border-b border-gray-400">
                <Link
                  :href="$getLocalizedUrl('my-rentbird.onboarding-tips')"
                  class="transition-colors hover:text-pink-500"
                >
                  {{ $trans('MyRentbirdNavbarDropdown.Tips') }}
                </Link>
              </div>
              <div class="pb-2 pt-2 border-b border-gray-400">
                <Link :href="$getLocalizedUrl('rental-sentiment')" class="transition-colors hover:text-pink-500">
                  Huursentiment 2024
                </Link>
              </div>
              <div class="pb-2 pt-2 border-b border-gray-400">
                <Link :href="$getLocalizedUrl('all-houses-categories')" class="transition-colors hover:text-pink-500">
                  {{ $trans('NavMenuFoldout.ViewApartments') }}
                </Link>
              </div>
              <div class="pb-2 pt-2 border-b border-gray-400">
                <Link :href="$getLocalizedUrl('about-us')" class="transition-colors hover:text-pink-500">
                  {{ $trans('NavMenuFoldout.AboutUs') }}
                </Link>
              </div>
            </div>
          </div>

          <div class="lg:w-[392px] lg:pt-16">
            <div v-if="user.isLoggedIn" class="mb-3 lg:mb-6">
              <div class="text-white text-xs lg:text-base">
                Account
              </div>
              <div
                class="text-base text-white font-medium hover:cursor-pointer hover:text-pink-500 transition-colors lg:text-2xl"
                @click="onClickLogoutUser"
              >
                {{ $trans('NavMenuFoldout.Logout') }}
                <Icon
                  name="logout"
                  class="relative left-[2px] lg:left-[6px] top-[-1px]"
                  icon-width="inline lg:w-[32px] lg:h-[32px]"
                />
              </div>
            </div>

            <NavMenuLanguageSwitcher />

            <NavMenuSupportCardList />
          </div>
        </div>
      </Container>
    </div>
  </div>
</template>
