<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import { useUser } from '@js/App/Api/User/User'
import { ref } from 'vue'
import Twinkle from '@images/icons/twinkle.svg'
import Image from '@js/Components/Image/Image.vue'
import { Link } from '@inertiajs/vue3'

const { user } = useUser()
const hasActiveSubscription = ref(user.value.hasActiveSubscription)
</script>

<template>
  <MyRentbirdCard :can-expand-on-mobile="false" :inactive-on-free-account="!hasActiveSubscription">
    <template #title>
      <div class="flex items-center">
        <Image :src="Twinkle" class="pr-2" />
        <div>{{ $trans('ResponseLetterCard.Title_v2') }}</div>
      </div>
    </template>

    <template #title-desktop-right>
      <Link
        class="text-sm text-blue-base hover:cursor-pointer hover:underline transition-all relative top-[1px]"
        :href="$getLocalizedUrl('my-rentbird.response-letter')"
      >
        {{ $trans('Generate') }}
      </Link>
    </template>

    <template #title-mobile-right>
      <Link
        class="text-sm text-blue-base hover:cursor-pointer hover:underline transition-all relative top-[1px]"
        :href="$getLocalizedUrl('my-rentbird.response-letter')"
      >
        {{ $trans('Generate') }}
      </Link>
    </template>

    <template #free-account-message>
      {{ $trans('MyRentbird.LetterCardFreeAccountMessage') }}
    </template>

    <template #default>
      {{ $trans('ResponseLetterCard.SubTitle_v2') }}
    </template>
  </MyRentbirdCard>
</template>
