<script setup>

import Icon from '@js/Components/Icon/Icon.vue'
import { onMounted, ref } from 'vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'

const props = defineProps({
  links: {
    type: Object,
    default: () => ({})
  },
  city: {
    type: Object,
    default: () => ({})
  }
})

const collapsed = ref(false)

const hasMounted = ref(false)
const minHeight = 180

const list = ref(null)
const listMaxHeight = ref(0)

onMounted(() => {
  listMaxHeight.value = list.value.clientHeight
  collapsed.value = true
  hasMounted.value = true
})
</script>

<template>
  <div class="bg-white rounded-md px-8 py-4 mb-4">
    <div class="justify-between z-10 hidden md:flex mt-2">
      <span class="text-3xl font-bold mb-4">{{ $trans("HouseListings.QuickSearch", {place: city.name}) }}</span>
      <div
        v-if="!collapsed && listMaxHeight > minHeight"
        class="px-2 -mr-2 cursor-pointer"
        @click="collapsed = true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
        >
          <path d="M27 22.5L18 13.5L9 22.5" stroke="#212020" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div
        v-if="collapsed && listMaxHeight > minHeight"
        class="px-2 -mr-2 cursor-pointer"
        @click="collapsed = false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
        >
          <path d="M9 13.5L18 22.5L27 13.5" stroke="#212020" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
    <div class="overflow-y-scroll md:overflow-hidden">
      <div
        ref="list"
        :class="{
          'flex gap-2 justify-between flex-grow flex-col lg:flex-row transition-all duration-300': true,
        }"

        :style="{
          maxHeight: hasMounted ? collapsed ? `${minHeight}px` : `${listMaxHeight}px` : 'inherit'
        }"
      >
        <div v-for="(subLinks, group) in links" :key="group" class="flex-1">
          <div class="text-lg md:text-xl font-bold">
            {{ $trans(`HouseListings.LinkCategory.${group}`) }}
          </div>
          <div
            :class="{
              'flex flex-wrap gap-2 mt-2': true,
            }"
          >
            <a
              v-for="link in subLinks"
              :key="link.slug"
              class="px-3 py-2 border-blue-400 md:text-lg text-blue-400 border rounded-md flex gap-1 max-w-fit"
              :href="getLocalizedUrl('all-houses-in-city-with-segment', {
                city: props.city.slug,
                segment: link.slug,
              })"
            ><span>{{ link.name }} ({{ link.amount > 999 ? '999+' : link.amount }})</span> <div class="scale-75"><Icon class="self-center" name="search" /></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
