<script setup>
defineProps({
  modelValue: Boolean
})

const id = `radiobutton-id-${Math.floor(Math.random() * 10000)}`

defineEmits(['update:modelValue'])

</script>
<template>
  <div
    class="flex flex-nowrap hover:cursor-pointer"
    @click="$emit('update:modelValue', !modelValue)"
  >
    <div
      class="rounded-full flex justify-center items-center relative mr-[8px]"
      :class="{
        'min-w-[22px] max-w-[22px] max-h-[22px] min-h-[22px] lg:min-w-[26px] lg:max-w-[26px] lg:max-h-[26px] lg:min-h-[26px] top-[0px] lg:top-0': !small,
        'min-w-[20px] max-w-[20px] max-h-[20px] min-h-[20px] lg:min-w-[20px] lg:max-w-[20px] lg:max-h-[20px] lg:min-h-[20px] top-[2px] lg:top-[3px]': small,
        'bg-success': modelValue,
        'bg-white border border-gray-100': !modelValue
      }"
    >
      <div
        v-if="modelValue"
        :class="`bg-white relative lg:left-0 rounded-full w-[8px] h-[8px] lg:w-[10px] lg:h-[10px]`"
      />
    </div>
    <label
      :for="id"
      class="text-gray-900 text-base hover:cursor-pointer"
      :class="{
        'lg:text-lg ': !small,

      }"
    >
      <slot /></label>
  </div>
</template>
