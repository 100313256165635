<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import { useUser } from '@js/App/Api/User/User'
import { ref } from 'vue'
import Icon from '@js/Components/Icon/Icon.vue'
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'
import { useMainStore } from '@js/App/Stores/MainStore'
import { trans } from '@js/App/Plugins/Translations'
import Image from '@js/Components/Image/Image.vue'
import Searchbuddy from '@images/icons/searchbuddy.svg'

defineProps({
  withTitleAndPadding: {
    type: Boolean,
    default: true
  }
})

const { user } = useUser()
const errors = ref({})
const searchBuddyEmail = ref(user.value.searchBuddyContactAddress)
const mainStore = useMainStore()

const onClickSaveButton = async () => {
  mainStore.startLoading()
  try {
    if (user.value.searchBuddyContactAddress) {
      return
    }
    if (!user.value.searchBuddyContactAddress && !searchBuddyEmail.value) {
      errors.value = {
        search_buddy_contact_address: trans('Errors.ThisFieldIsRequired')
      }
      return
    }
    const response = await doPostRequest('/api/user/set-search-buddy', {
      search_buddy_contact_method: 'email',
      search_buddy_contact_address: searchBuddyEmail.value
    })
    if (response.hasErrors) {
      errors.value = response.errors
    } else {
      errors.value = {}
      reloadPropsFromServer()
    }
  } finally {
    mainStore.stopLoading()
  }
}

const onClickDeleteButton = async () => {
  mainStore.startLoading()
  const response = await doPostRequest('/api/user/set-search-buddy', {
    search_buddy_contact_method: 'email',
    search_buddy_contact_address: null
  })
  if (response.hasErrors) {
    errors.value = response.errors
  } else {
    errors.value = {}
    searchBuddyEmail.value = null
    reloadPropsFromServer()
  }
  mainStore.stopLoading()
}
</script>

<template>
  <MyRentbirdCard
    :inactive-on-free-account="!user.hasActiveSubscription"
    :default-is-expanded="!withTitleAndPadding"
    :can-expand-on-mobile="!withTitleAndPadding"
    :class="{
      '!p-0': !withTitleAndPadding
    }"
  >
    <template #title>
      <div v-if="withTitleAndPadding" class="flex items-center">
        <Image :src="Searchbuddy" class="pr-2" />
        <div>{{ $trans('SearchBuddyCard.Title') }}</div>
      </div>
    </template>

    <template #free-account-message>
      {{ $trans('SearchBuddyCard.FreeAccountMessage') }}
    </template>

    <template #body-that-is-always-shown>
      <div v-if="!user.searchBuddyContactAddress" class="text-sm pb-2 text-danger lg:hidden">
        <div>
          <Icon name="cross" icon-width="inline w-[20px] h-[20px]" />
          {{ $trans('SearchBuddyCard.NoSearchBuddy') }}
        </div>
      </div>
      <div v-else class="pb-2 text-sm text-success lg:hidden">
        <Icon name="check" icon-width="inline w-[20px] h-[20px]" />
        {{ $trans('SearchBuddyCard.SearchBuddyAdded') }}
      </div>
    </template>

    <template #title-desktop-right>
      <span v-if="user.searchBuddyContactAddress">
        <a
          class="text-base text-blue-base hover:cursor-pointer hover:underline transition-all relative top-[1px]"
          @click="onClickDeleteButton"
        >{{ $trans('Common.Delete') }}</a>
      </span>
    </template>

    <template #default>
      <div v-if="user.searchBuddyContactAddress" class="text-end mt-[-2rem] mb-2 lg:hidden">
        <span>
          <a
            class="text-base text-blue-base hover:cursor-pointer hover:underline transition-all relative"
            @click="onClickDeleteButton"
          >{{ $trans('Common.Delete') }}</a>
        </span>
      </div>
      <div v-if="withTitleAndPadding" class="block pb-4">
        {{ $trans('SearchBuddyCard.SubTitle') }}
      </div>

      <div class="w-full bg-gray-50 text-purple-700 p-4 rounded-t-md block transition-colors">
        <TextInput
          id="email"
          v-model="searchBuddyEmail"
          :disabled="user.searchBuddyContactAddress"
          :placeholder="$trans('SearchBuddyCard.EmailPlaceholder')"
          :error="errors.search_buddy_contact_address"
          class="pb-4"
        >
          <template #label>
            {{ $trans('SearchProfileWizard.Email') }}
          </template>
        </TextInput>
        <Button
          :big="true"
          :disabled="user.searchBuddyContactAddress || !user.hasActiveSubscription"
          :full-width-on-mobile="true"
          :full-width-on-desktop="true"
          icon-right="save"
          @click="
            () => {
              if (user.searchBuddyContactAddress || !user.hasActiveSubscription) {
                return
              }
              onClickSaveButton()
            }
          "
        >
          <span class="whitespace-nowrap">
            {{ !user.searchBuddyContactAddress ? $trans('Common.Save') : $trans('Common.Saved') }}
          </span>
        </Button>
      </div>
    </template>
  </MyRentbirdCard>
</template>
