import { usePaymentStore } from '@js/App/Stores/PaymentStore'
import { ON_COMPLETE_REDIRECT_TO_CHECKOUT_PAGE, showCreateSearchProfileWizard } from '@js/App/SearchProfile'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { computed, onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { router, usePage } from '@inertiajs/vue3'
import { useMainStore } from '@js/App/Stores/MainStore'
import { trans } from '@js/App/Plugins/Translations'
import { getLocale } from '@js/App/Utils/CountryAndLocale'
import { trackAddToCart, trackInitiateCheckout } from '@js/App/Tracking/Tracking'
import { getFromCache } from '@js/App/Performance/LocalCache'
import { useUser } from '@js/App/Api/User/User'
import {
  PLAN_1_MONTHLY,
  PLAN_1_MONTHLY_FREE_WHATSAPPP,
  PLAN_1_MONTHLY_HIGH,
  PLAN_1_MONTHLY_SUPER_HIGH,
  US_PLAN_1_MONTHLY,
  US_PLAN_1_MONTHLY_HIGH
} from '@js/App/Prices'
import { getQueryParameter } from '@js/App/Utils/ReadQueryParams'

export const useStripeCheckoutForm = (stripeElementWrapperRef, countryOfResidenceRef) => {
  const stripe = ref(null)
  const elements = ref(null)
  const paymentIntent = ref(null)
  const clientSecret = ref(null)
  const showStripeElementLoader = ref(true)
  const isSpecialFreeCoupon = ref(false)
  const error = ref('')

  const { selectedPrice, couponCodeThatWasApplied } = storeToRefs(usePaymentStore())
  const { user } = useUser()
  const userServerId = computed(() => user.value.serverId)

  onMounted(async () => {
    showStripeElementLoader.value = true

    // Don't await this so that we can load the payment intent in parallel.
    getStripeObject().then((stripeObject) => {
      stripe.value = stripeObject
    })

    if (!selectedPrice.value) {
      return
    }

    const paymentIntentFromServer = await createPaymentIntent(
      selectedPrice.value.plan,
      couponCodeThatWasApplied.value,
      userServerId.value,
      countryOfResidenceRef.value
    )
    if (!paymentIntentFromServer) {
      error.value = trans('Payments.NoPaymentIntentError')
      showStripeElementLoader.value = false
      return
    }
    if (paymentIntentFromServer === 'FREE_ACCOUNT') {
      paymentIntent.value = paymentIntentFromServer
      showStripeElementLoader.value = false
      isSpecialFreeCoupon.value = true
      return
    }
    paymentIntent.value = paymentIntentFromServer
    clientSecret.value = paymentIntentFromServer?.client_secret
  })

  // Whenever the price changes, we update the Stripe.elements form.
  watch([selectedPrice, countryOfResidenceRef], async () => {
    if (!selectedPrice.value) {
      return
    }

    showStripeElementLoader.value = true
    const paymentIntent = await createPaymentIntent(
      selectedPrice.value.plan,
      couponCodeThatWasApplied.value,
      userServerId.value,
      countryOfResidenceRef.value
    )

    if (!paymentIntent) {
      error.value = trans('Payments.NoPaymentIntentError')
      showStripeElementLoader.value = false
      return
    }
    if (paymentIntent === 'FREE_ACCOUNT') {
      showStripeElementLoader.value = false
      isSpecialFreeCoupon.value = true
      return
    }
    clientSecret.value = paymentIntent.client_secret
  })

  watch(clientSecret, async () => {
    showStripeElementLoader.value = true // This should already be true, but just to be safe.

    await waitForStripeToBeReady()

    elements.value = stripe.value.elements({
      clientSecret: clientSecret.value,
      appearance: STRIPE_ELEMENTS_APPEARANCE,
      loader: 'always',
      locale: getLocale(),
      fonts: [
        {
          family: 'ABCNormal',
          src: 'url(https://rentbird.nl/fonts/abcnormal/ABCNormal-Regular.woff2)',
          weight: '400'
        }
      ]
    })
    const el = elements.value.create('payment', getStripePayElementOptions())
    el.mount(stripeElementWrapperRef.value)
    el.on('ready', (event) => {
      showStripeElementLoader.value = false
    })
  })

  const waitForStripeToBeReady = () =>
    new Promise((resolve) => {
      const i = setInterval(() => {
        if (!stripe.value) {
          return
        }
        clearInterval(i)
        resolve()
      }, 150)
    })

  return { stripe, elements, clientSecret, showStripeElementLoader, error, isSpecialFreeCoupon, paymentIntent }
}

export const onClickConfirmPaymentMethod = async (
  stripe,
  elements,
  paymentIntent,
  isSpecialFreeCoupon,
  isFromMobile
) => {
  const mainStore = useMainStore()
  mainStore.startLoading()

  // This might be better lived inside the app and passed along
  // I do not currently know the security risks involved with that method so we are hardcoding it for now
  const mobileReturnUrl = 'rentbird://upgrade'

  if (isSpecialFreeCoupon.value) {
    const { selectedPrice, couponCodeThatWasApplied } = storeToRefs(usePaymentStore())
    const response = await doPostRequest('/api/user/use-free-account-coupon', {
      coupon: couponCodeThatWasApplied.value,
      plan: selectedPrice.value.plan
    })
    if (response.hasErrors) {
      mainStore.stopLoading()
      return {
        errors: {
          general: trans('Payments.FreeCouponCodeError')
        }
      }
    }
    if (isFromMobile) {
      window.location.href = `${mobileReturnUrl}?redirect_status=succeeded`
    } else {
      window.location.href = getLocalizedUrl('my-rentbird.home')
    }
    return
  }

  const { error: submitError } = await elements.value.submit()
  if (submitError) {
    mainStore.stopLoading()
    return {
      errors: {
        general: trans('Payments.StripeGeneralError', {
          message: submitError?.message
        })
      }
    }
  }

  const { user } = useUser()

  await trackInitiateCheckout()

  const defaultReturnUrl = getLocalizedUrl(
    'checkout.completed',
    {},
    {
      email: user.value.email
    }
  )

  const result = await stripe.value.confirmPayment({
    elements: elements.value,
    clientSecret: paymentIntent?.client_secret,
    confirmParams: {
      return_url: isFromMobile ? mobileReturnUrl : defaultReturnUrl,
      payment_method_data: {
        billing_details: {
          name: `${user.value.firstNameFormatted} ${user.value.lastNameFormatted}`,
          email: user.value.email
        }
      }
    }
  })

  if (result.error) {
    mainStore.stopLoading()
    return {
      errors: {
        general: trans('Payments.StripeGeneralError', {
          message: result.error?.message
        })
      }
    }
  }

  return { errors: {} }
}

/**
 * What to do when a pricing card "pay now" button has been clicked. This will either open the payment modal,
 * or if no user is logged in the createSearchProfile modal.
 */
export const onClickPricingCardPayButton = async () => {
  const paymentStore = usePaymentStore()
  const mainStore = useMainStore()
  const isFromMobile = getQueryParameter('isFromMobile', 'false')

  if (!paymentStore.selectedPrice) {
    return false
  }

  const { user } = useUser()

  if (!user.value.isLoggedIn) {
    showCreateSearchProfileWizard(ON_COMPLETE_REDIRECT_TO_CHECKOUT_PAGE)
    return true
  }

  mainStore.startLoading()

  await trackAddToCart(
    paymentStore.selectedPrice?.plan,
    '',
    0,
    paymentStore.selectedPrice?.amount,
    paymentStore.selectedPrice?.numberOfMonths
  )
  router.visit(
    getLocalizedUrl(
      'checkout.start',
      {},
      {
        plan: paymentStore.selectedPrice?.plan,
        isFromMobile
      }
    )
  )
  return true
}

export const getStripeObject = () =>
  new Promise((resolve, reject) => {
    const stripeKey = getStripeKey()
    if (window.Stripe) {
      return resolve(window.Stripe(stripeKey))
    }

    const i = setInterval(() => {
      if (window.Stripe) {
        clearInterval(i)
        return resolve(window.Stripe(stripeKey))
      }
    }, 100)
  })

export const getFullPricePerMonth = () => {
  const { availablePrices } = storeToRefs(usePaymentStore())
  const price = availablePrices.value.find(item =>
    [
      US_PLAN_1_MONTHLY_HIGH,
      US_PLAN_1_MONTHLY,
      PLAN_1_MONTHLY,
      PLAN_1_MONTHLY_FREE_WHATSAPPP,
      PLAN_1_MONTHLY_HIGH,
      PLAN_1_MONTHLY_SUPER_HIGH
    ].includes(item.plan)
  )
  if (!price) {
    return 2900
  }

  return price.amount
}

const getStripeKey = () => {
  const page = usePage()
  return page.props.stripe_publishable_key
}

const createPaymentIntent = async (plan, couponCodeUsed, userServerId, countryOfResidence) => {
  let postData = {}
  try {
    const debugHistoryArray = getFromCache('debug_track_history') || []
    postData = {
      plan,
      coupon: couponCodeUsed,
      u: userServerId,
      country_of_residence: countryOfResidence,
      debugData: {
        fullUrl: window.location.href,
        urlHistory: debugHistoryArray.slice(Math.max(debugHistoryArray.length - 10, 0))
      }
    }
    const response = await doPostRequest('/api/payments/payment-intent', postData, false)

    return response.json?.payment_intent
  } catch (e) {
    console.error('Got an error on creating payment intent:')
    console.error(e)
    console.error(JSON.stringify(postData))

    throw e
  }
}

const getStripePayElementOptions = () => {
  const { user } = useUser()

  return {
    layout: {
      type: 'tabs',
      defaultCollapsed: false
    },
    paymentMethodOrder: ['ideal'], // This makes sure iDeal is always shown first, has no impact on the other ordering
    defaultValues: {
      billingDetails: {
        name: `${user.value.firstNameFormatted} ${user.value.lastNameFormatted}`,
        email: user.value.email
      }
    },
    fields: {
      billingDetails: {
        name: 'never',
        email: 'never'
      }
    },
    terms: {
      card: 'never',
      applePay: 'never',
      googlePay: 'never',
      ideal: 'never',
      paypal: 'never'
    },
    wallets: {
      applePay: 'auto',
      googlePay: 'auto'
    }
  }
}

const STRIPE_ELEMENTS_APPEARANCE = {
  theme: 'flat',
  variables: {
    fontFamily: 'ABCNormal',
    fontWeightMedium: '400',
    colorPrimaryText: 'rgb(40, 33, 75)',
    colorText: 'black',
    colorDanger: '#EA1717',
    fontSizeBase: '16px',
    borderRadius: '4px',
    colorPrimary: 'rgb(231, 28, 119)',
    colorBackground: 'white'
  },
  rules: {
    '.Tab': {
      backgroundColor: 'white',
      border: '1px solid rgb(202,202,202)'
    },
    '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
      boxShadow: 'none',
      outline: 'none',
      backgroundColor: 'rgb(251, 212, 230)',
      borderColor: 'rgb(231,28,119)'
    },
    '.Input, .CheckboxInput': {
      borderRadius: '4px',
      border: '1px solid rgb(209,209,209)',
      borderColor: 'rgb(209,209,209)'
    },
    '.CheckboxLabel': {
      fontWeight: 'normal'
    },
    '.Text--redirect': {
      color: 'rgb(141, 143, 144)'
    }
  }
}
