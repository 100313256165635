<script setup>
import CallToActionSectionActivateAlertsLarge
  from '@js/Components/Marketing/CallToActionSection/CallToActionSectionActivateAlertsLarge.vue'
import ListingPageLayout from '@js/Components/ListingPages/ListingPageLayout.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { trans } from '@js/App/Plugins/Translations'

const props = defineProps({
  places: {
    type: Array,
    default: () => ([])
  },
  province: {
    type: Object,
    default: () => ({})
  },
  totalHouses: {
    type: Number,
    default: 0
  }
})

const placesByLetter = props.places.reduce((acc, el) => {
  const firstLetter = el.name.toLowerCase().substring(0, 1)
  const existing = acc[firstLetter]

  if (!existing) {
    acc[firstLetter] = [el]
  } else {
    existing.push(el)
  }
  return acc
}, {})

const breadcrumbs = [
  {
    url: getLocalizedUrl('all-houses-categories'),
    name: trans('HouseListings.Breadcrumbs.AllHouses')
  },
  {
    url: getLocalizedUrl('all-houses-in-province', {
      province: props.province.slug
    }),
    name: props.province.name
  }
]
if (props.currentSegment) {
  breadcrumbs.push({
    url: '#',
    name: props.currentSegment.name
  })
}
</script>
<template>
  <ListingPageLayout
    :title="$trans('HouseListings.ProvinceHeroTitle', { name: province.name })"
    :sub-title="$trans('HouseListings.HeroSubTitle')"
    :description="$trans('HouseListings.HeroDescription')"
    :breadcrumbs="breadcrumbs"
  >
    <div>
      <div class="flex justify-start ml-1 md:ml-6 box-border gap-1 md:gap-2">
        <a
          class="inline-block text-sm bg-white text-pink-500 px-4 py-1 rounded-t"
          :href="getLocalizedUrl('all-houses-in-city', {
            city: province.slug
          })"
        >{{ $trans('ProvinceListings.Menu.Cities') }}</a>
        <a
          class="inline-block text-sm bg-gray-100 text-purple-700 hover:text-pink-500 hover:bg-white  px-4 py-1 rounded-t"
          :href="getLocalizedUrl('all-houses-in-province', {
            province: province.slug
          })"
        >{{ $trans('HouseListings.Menu.AllHouses', {amount: totalHouses}) }}</a>
      </div>

      <div class="bg-white px-6 py-4 rounded-md">
        <h1 class="text-2xl lg:text-4xl font-medium text-purple-900 my-2 mb-8">
          {{ $trans('HouseListings.PromotedCityHeading') }}
        </h1>
        <div class="columns-1 md:columns-2 lg:columns-3 gap-4 pb-4">
          <div v-for="(plcs, firstLetter) in placesByLetter" :key="firstLetter" class="mb-4">
            <div class="capitalize font-bold text-xl mb-2">
              {{ firstLetter }}
            </div>
            <div v-for="place in plcs" :key="firstLetter+place">
              <a
                class="inline-block text-lg my-0.5 hover:text-pink-500"
                :href="getLocalizedUrl('all-houses-in-city', { city: place.slug })"
              >{{ place.name }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CallToActionSectionActivateAlertsLarge />
  </ListingPageLayout>
</template>
<style lang="scss" scoped>
.place_card {
  aspect-ratio: 9 / 16;
}
</style>
