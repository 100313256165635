<script setup>
import ColdFaceImage from '@images/icons/cold-face.png'
import { computed } from 'vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import FireImage from '@images/icons/fire.png'
import DeadFaceImage from '@images/icons/dead.png'
import ThumbsUpImage from '@images/icons/thumbs-up.png'

const props = defineProps({
  isLoading: Boolean,
  matchesPerWeek: [Number, String],
  isForAllSearchProfiles: {
    type: Boolean,
    default: false
  }
})

const matchIcon = computed(() => {
  if (props.isLoading) {
    return matchIcon.value || FireImage
  }
  if (props.matchesPerWeek <= 2) {
    return DeadFaceImage
  }
  if (props.matchesPerWeek <= 5) {
    return ColdFaceImage
  }
  if (props.matchesPerWeek <= 10) {
    return ThumbsUpImage
  }

  return FireImage
})

const backgroundColor = computed(() => {
  if (props.isLoading) {
    return backgroundColor.value || 'bg-blue-50'
  }
  if (props.matchesPerWeek <= 2) {
    return 'bg-pink-50'
  }
  if (props.matchesPerWeek <= 5) {
    return 'bg-[#FFF2D9]'
  }
  if (props.matchesPerWeek <= 10) {
    return 'bg-[#E2FFF5]'
  }

  return 'bg-[#E2FFF5]'
})

const showNoMatchesWarning = computed(() => backgroundColor?.value === 'bg-pink-100')
</script>
<template>
  <InfoMessageBox :image="matchIcon" :color="backgroundColor">
    <div
      v-if="!showNoMatchesWarning"
      v-html="
        $trans(
          isForAllSearchProfiles
            ? 'NumberOfMatchesMessageBox.NumberOfMatchesTextAll'
            : 'NumberOfMatchesMessageBox.NumberOfMatchesText',
          {
            matches: isLoading ? '.. matches' : `${matchesPerWeek} matches`
          }
        )
      "
    />
    <div v-else v-html="$trans('NumberOfMatchesMessageBox.NoMatchesWarning')" />
  </InfoMessageBox>
</template>
