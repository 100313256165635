<script setup>
import UserProfileStatCard from '@js/Components/MyRentbird/UserProfileStatsCard/UserProfileStatCard.vue'
import { usePage } from '@inertiajs/vue3'
import Mag from '@images/icons/mag.png'
import House from '@images/icons/house.png'
import Eye from '@images/icons/eye.png'
import HeartEyes from '@images/icons/heart_eyes.png'

const page = usePage()
const searchStats = page.props.searchStats || []
</script>
<template>
  <div class="grid grid-cols-2 lg:grid-cols-4 gap-3">
    <UserProfileStatCard
      :label="$trans('ProfileStats.SearchLength.Label')"
      :value="
        $trans('ProfileStats.SearchLength.Value', {
          amount: searchStats.days
        })
      "
      :icon="Mag"
    />
    <UserProfileStatCard
      :label="$trans('ProfileStats.HouseMatches.Label')"
      :value="
        $trans('ProfileStats.HouseMatches.Value', {
          amount: searchStats.matches
        })
      "
      :icon="House"
    />
    <UserProfileStatCard
      :label="$trans('ProfileStats.ViewedHouses.Label')"
      :value="
        $trans('ProfileStats.ViewedHouses.Value', {
          amount: searchStats.viewed
        })
      "
      :icon="Eye"
    />
    <UserProfileStatCard
      :label="$trans('ProfileStats.SavedTime.Label')"
      :value="
        $trans('ProfileStats.SavedTime.Value', {
          amount: searchStats.saved_time
        })
      "
      :icon="HeartEyes"
    />
  </div>
</template>
