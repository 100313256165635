<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import { usePage, Link } from '@inertiajs/vue3'
import { computed } from 'vue'
import SearchProfileCard from '@js/Components/SearchProfileCard/SearchProfileCard.vue'
import { getSearchProfileFromDto } from '@js/App/Stores/SearchProfileStore'
import NumberOfMatchesBoxAllSearchProfiles from '@js/Components/NumberOfMatchesBox/NumberOfMatchesBoxAllSearchProfiles.vue'
import { useUser } from '@js/App/Api/User/User'
import Image from '@js/Components/Image/Image.vue'
import MagPink from '@images/icons/mag-pink.svg'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import DeadFaceImage from '@images/icons/dead.png'
import LockImage from '@images/icons/lock.png'

const page = usePage()

const { user } = useUser()
const userSearchProfiles = (page.props.userSearchProfiles || []).map(searchProfileDto =>
  getSearchProfileFromDto(searchProfileDto)
)

const canCreateNewSearchProfile = computed(
  () => (user.value.hasActiveSubscription ? user.value.maxNumberOfSearchProfiles : 1) - userSearchProfiles.length > 0
)
</script>
<template>
  <MyRentbirdCard :can-expand-on-mobile="false">
    <template #title>
      <div class="flex items-center">
        <Image :src="MagPink" class="pr-2" />
        <div>{{ $trans('SearchProfilesOverviewCard.Searches') }}</div>
      </div>
    </template>
    <template #title-desktop-right>
      <span>
        <Link
          class="text-sm text-blue-base hover:cursor-pointer hover:underline transition-all relative top-[1px]"
          :href="$getLocalizedUrl('my-rentbird.preferences')"
        >{{ $trans('Settings') }}</Link>
      </span>
    </template>
    <template #default>
      <NumberOfMatchesBoxAllSearchProfiles v-if="userSearchProfiles.length" />
      <InfoMessageBox v-if="!userSearchProfiles.length" :image="DeadFaceImage" color="red">
        <div v-if="!showNoMatchesWarning" v-html="$trans('SearchProfilesOverviewCard.NotSearching')" />
        <div v-else v-html="$trans('NumberOfMatchesMessageBox.NoMatchesWarning')" />
      </InfoMessageBox>

      <div class="lg:flex lg:flex-wrap lg:mt-[8px] lg:ml-[-4px] lg:mr-[-4px]">
        <div
          v-for="searchProfile of userSearchProfiles"
          :key="searchProfile.serverId"
          class="w-full lg:pr-[4px] lg:pl-[4px]"
        >
          <SearchProfileCard
            class="mt-[8px]"
            :search-profile="searchProfile"
            :show-edit-button="true"
            :rounded-bottom="true"
          />
        </div>
      </div>

      <div v-show="canCreateNewSearchProfile" class="w-full flex mt-[16px]">
        <Link
          class="text-sm lg:text-base text-blue-base hover:cursor-pointer hover:underline transition-all"
          :href="$getLocalizedUrl('my-rentbird.create-search-profile')"
        >
          {{ $trans('SearchProfilesOverviewCard.NewSearch') }}
        </Link>
      </div>
      <InfoMessageBox
        v-if="!canCreateNewSearchProfile && !user.hasActiveSubscription"
        :image="LockImage"
        color="red"
        class="mt-[16px]"
      >
        <div v-html="$trans('SearchProfilesOverviewCard.FreeRentBirdOneSearchProfile')" />
      </InfoMessageBox>
    </template>
  </MyRentbirdCard>
</template>
