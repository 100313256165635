import { CREATE_SEARCH_PROFILE_AND_ACCOUNT_MODAL_ID, toggleModal } from '@js/App/Plugins/Modals'
import { useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { useMainStore } from '@js/App/Stores/MainStore'
import { usePaymentStore } from '@js/App/Stores/PaymentStore'
import { storeToRefs } from 'pinia'
import { trackAddToCart } from '@js/App/Tracking/Tracking'

export const ON_COMPLETE_REDIRECT_TO_UPGRADE_PAGE = 'redirect-to-upgrade-page'
export const ON_COMPLETE_REDIRECT_TO_CHECKOUT_PAGE = 'redirect-to-checkout-page'

export const showCreateSearchProfileWizard = (onComplete = ON_COMPLETE_REDIRECT_TO_UPGRADE_PAGE, startingStep = 1) => {
  const { actionAfterCompletion, step } = storeToRefs(useSearchProfileStore())
  actionAfterCompletion.value = onComplete
  step.value = startingStep

  toggleModal(CREATE_SEARCH_PROFILE_AND_ACCOUNT_MODAL_ID)
}

/**
 * This method is called from the SearchProfile when it has been successfully completed. The next action to do is
 * determined here.
 * @param onCompleteAction {string} should be one of the constant in the top of this file.
 */
export const onCompletedSearchProfile = async (onCompleteAction) => {
  const mainStore = useMainStore()
  const paymentStore = usePaymentStore()

  if (onCompleteAction === ON_COMPLETE_REDIRECT_TO_UPGRADE_PAGE) {
    // Why window.location.href instead of Inertia router.visit? Because this will make sure that the user is logged in
    // and the session cookie is set correctly.
    window.location.href = getLocalizedUrl(
      'upgrade',
      {},
      {
        plan: paymentStore.selectedPrice?.plan
      }
    )

    return
  }

  if (onCompleteAction === ON_COMPLETE_REDIRECT_TO_CHECKOUT_PAGE) {
    mainStore.startLoading()

    await trackAddToCart(
      paymentStore.selectedPrice?.plan,
      '',
      0,
      paymentStore.selectedPrice?.amount,
      paymentStore.selectedPrice?.numberOfMonths
    )

    // Why window.location.href instead of Inertia router.visit? Because this will make sure that the user is logged in
    // and the session cookie is set correctly.
    window.location.href = getLocalizedUrl(
      'checkout.start',
      {},
      {
        plan: paymentStore.selectedPrice?.plan
      }
    )
  }
}
