<script setup>
import { usePaymentStore } from '@js/App/Stores/PaymentStore'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import { formatPrice } from '@js/App/Plugins/Formatting'
import Button from '@js/Components/Forms/Button/Button.vue'
import { onClickPricingCardPayButton } from '@js/App/Api/Payments'
import PricingCardRentbirdButton
  from '@js/Components/PricingCard/PricingCardRentbird/PricingCardRentbirdButton.vue'
import { trackSubscriptionSelection } from '@js/App/Tracking/Tracking'
import RentbirdLogo from '@js/Components/Logos/RentbirdLogo/RentbirdLogo.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { useUser } from '@js/App/Api/User/User'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'

const paymentStore = usePaymentStore()
const { selectedPrice, availablePrices } = storeToRefs(usePaymentStore())
const { user, userActivePayment } = useUser()

const setSelectedPrice = (price) => {
  paymentStore.removeCoupon()
  selectedPrice.value = price

  trackSubscriptionSelection(selectedPrice.value.plan)
}
onMounted(() => {
  setSelectedPrice(0)
})
</script>
<template>
  <div class="w-full bg-purple-900 text-white p-6 rounded-lg h-full">
    <div class="flex justify-between items-center mb-4">
      <RentbirdLogo />
      <div class="flex flex-col items-end text-right">
        <div class="font-medium flex items-start">
          <h4 class="text-5xl inline">
            16
          </h4><h5 class="text-xl inline">
            ,33
          </h5>
        </div>

        <p>{{ $trans('PricingCardRentbird.FromPrice') }}</p>
      </div>
    </div>

    <div class="text-[#0CCBFF]">
      <span class="text-sm px-3 py-1 rounded-lg  bg-[#004254]">{{ $trans('PricingCardRentbird.Highlight1') }}</span>
      +
      <span class="text-sm px-3 py-1 rounded-lg  bg-[#004254]">{{ $trans('PricingCardRentbird.Highlight2') }}</span>
    </div>

    <hr class="my-8 border border-[#EAEAEB80]">

    <p class="text-white text-base lg:text-xl hidden lg:block">
      {{ $trans('Includes') }}
    </p>
    <div class="flex mt-4">
      <Icon name="check-circle" class="mr-2 lg:mr-3 text-success" icon-width="lg:w-[24px] lg:h-[24px]" />
      <p class="text-base relative top-[-3px] lg:top-0">
        {{ $trans('PricingCardRentbird.Reason1') }}
      </p>
    </div>
    <div class="flex mt-4">
      <Icon name="check-circle" class="mr-2 lg:mr-3 text-success" icon-width="lg:w-[24px] lg:h-[24px]" />
      <p class="text-base relative top-[-3px] lg:top-0">
        {{ $trans('PricingCardRentbird.Reason2') }}
      </p>
    </div>
    <div class="flex mt-4">
      <Icon name="check-circle" class="mr-2 lg:mr-3 text-success" icon-width="lg:w-[24px] lg:h-[24px]" />
      <p class="text-base relative top-[-3px] lg:top-0">
        {{ $trans('PricingCardRentbird.Reason3') }}
      </p>
    </div>
    <div class="flex mt-4 mb-8 lg:mb-10">
      <Icon name="check-circle" class="mr-2 lg:mr-3 text-success" icon-width="lg:w-[24px] lg:h-[24px]" />
      <p class="text-base relative top-[-3px] lg:top-0">
        {{ $trans('PricingCardRentbird.Reason4') }}
      </p>
    </div>

    <div class="flex-row justify-between items-center mb-4">
      <PricingCardRentbirdButton
        v-for="(price,index) of availablePrices.map(item => item)"
        :key="price.plan"
        :index="index"
        :is-active="selectedPrice?.plan === price.plan"
        :price="price"
        @click="setSelectedPrice(price)"
      >
        <template #title>
          <div>
            <div class="flex flex-row items-center">
              <span class="text-base lg:text-lg">{{ $trans('PricingCardSimple.Discount', { discount: price.discount || 0 }) }}</span>
              <span v-if="index === 0 && (!user.isLoggedIn || userActivePayment === null)" class="text-blue-base text-xs border border-blue-base px-4 py-0.5 lg:px-[6px] lg:ml-[8px] rounded-lg hidden xl:flex">
                {{ $trans('PricingCardSimpleButton.Label') }}
              </span>
              <span v-if="user.isLoggedIn && userActivePayment && price.plan === userActivePayment.plan" class="text-blue-base text-xs border border-blue-base px-4 py-0.5 lg:px-[6px] lg:ml-[8px] rounded-lg hidden xl:flex">
                {{ $trans('PricingCardSimpleButton.LabelUser') }}
              </span>
            </div>

            <div class="text-gray-201 text-xs hidden xl:flex font-normal">
              {{ $trans('PricingCardAlternative.DisclaimerText') }}
            </div>
          </div>
        </template>

        <template #price>
          <div
            class="text-sm lg:text-base lg:font-medium"
            :class="{
              'text-success': index !== 2,
              'text-danger-soft': index === 2,
            }"
          >
            {{ formatPrice(availablePrices[index].amountPerMonth) }} {{ $trans('PricingCardAlternative.PerMonth') }}
          </div>
          <span class="text-xs text-gray-201">   {{ $trans(price.numberOfMonths > 1 ? 'PricingCardSimple.Months' : 'PricingCardSimple.MonthsSingle', {
            months: price.numberOfMonths
          }) }}</span>
        </template>
      </PricingCardRentbirdButton>
    </div>

    <div class="block lg:flex lg:flex-row lg:items-center justify-start">
      <Button
        v-if="!user.isLoggedIn"
        class="w-full"
        :full-width-on-desktop="true"
        :full-width-on-mobile="true"
        :big="true"
        :disabled="!selectedPrice"
        icon-right="search"
        @click="onClickPricingCardPayButton"
      >
        {{ $trans('PricingCardRentbird.StartSearch') }}
      </Button>
      <Button
        v-if="user.isLoggedIn && userActivePayment"
        class="w-full"
        tag="a"
        :full-width-on-desktop="true"
        :full-width-on-mobile="true"
        :big="true"
        :disabled="!selectedPrice"
        icon-right="edit"
        :href="getLocalizedUrl('my-rentbird.subscription')"
      >
        {{ $trans('PricingCardRentbird.ChangePlan') }}
      </Button>
      <Button
        v-if="user.isLoggedIn && !userActivePayment"
        class="w-full"
        tag="a"
        :full-width-on-desktop="true"
        :full-width-on-mobile="true"
        :big="true"
        :disabled="!selectedPrice"
        icon-right="play-outline"
        :href="getLocalizedUrl('upgrade', {}, { plan: selectedPrice?.plan })"
      >
        {{ $trans('PricingCardRentbird.ChangePlanNoSubscription') }}
      </Button>
    </div>
  </div>
</template>
