<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { ref } from 'vue'
import ProgressBar from '@js/Components/Forms/ProgressBar/ProgressBar.vue'
import { useUser } from '@js/App/Api/User/User'
import { saveUserPersonalData } from '@js/App/Api/User/UserPersonalData'
import { reloadPropsFromServer } from '@js/App/Api/Infra/ServerData'
import { router } from '@inertiajs/vue3'
import DateOfBirthInput from '@js/Components/Forms/DateOfBirthInput/DateOfBirthInput.vue'
import PhoneNumberInput from '@js/Components/Forms/PhoneNumberInput/PhoneNumberInput.vue'
import { doPostRequest } from '@js/App/Api/Infra/ApiClient'
import LanguageSwitcherDropdown from '@js/Components/Forms/LanguageSwitcherDropdown/LanguageSwitcherDropdown.vue'
import Select from '@js/Components/Forms/Select/Select.vue'
import { isEmpty } from '@js/App/Utils/IsEmpty'
import { useMainStore } from '@js/App/Stores/MainStore'
import Icon from '@js/Components/Icon/Icon.vue'

const { user, userPersonalData } = useUser()
const internalPersonalData = ref({ ...userPersonalData.value })
const phone = ref(user.value.phone)

const errors = ref({})

const onClickNext = async () => {
  const mainStore = useMainStore()

  try {
    mainStore.startLoading()
    errors.value = {}
    const { errors: apiErrors } = await saveUserPersonalData(internalPersonalData.value, [
      'date_of_birth', 'gender'
    ])

    errors.value = apiErrors

    if (phone.value) {
      const response = await doPostRequest('/api/user/phone/validate-and-update', {
        phone: phone.value,
        validation_code: 'NO_VERIFICATION_NEEDED'
      })
      if (response.hasErrors) {
        errors.value = {
          ...errors.value,
          ...response.errors
        }
      }
    }

    if (!isEmpty(errors.value)) {
      return
    }

    reloadPropsFromServer()

    router.get(getLocalizedUrl('my-rentbird.onboarding', { step: 5 }))
  } finally {
    mainStore.stopLoading()
  }
}
</script>
<template>
  <div>
    <div class="lg:flex justify-between items-center">
      <h1 class="text-[#151227] font-bold text-2xl lg:text-4xl">
        {{ $trans('MandatoryOnboardingStep4.Title') }}
      </h1>
      <div class="lg:w-[20%]">
        <ProgressBar color="green" :progress="40" :show-percentage="true" />
      </div>
    </div>
    <MyRentbirdCard class="mt-[16px] lg:mt-[20px]" :can-expand-on-mobile="false" :default-is-expanded="true">
      <DateOfBirthInput
        v-model="internalPersonalData.date_of_birth"
        :error="errors.date_of_birth"
        class="mb-[16px]"
      >
        <template #label>
          {{ $trans('StepDateOfBirthAndMore.Label') }}
        </template>
      </DateOfBirthInput>

      <PhoneNumberInput
        v-model="phone"
        :require-validation="false"
        class="mb-[16px]"
        :error="errors.phone"
      />

      <Select
        v-model="internalPersonalData.gender"
        :error="errors.gender"
        class="mb-[16px]"
        :options="[{
          label: $trans('Common.Man'),
          value: 'man',
        }, {
          label: $trans('Common.Woman'),
          value: 'woman',
        }, {
          label: $trans('Common.Other'),
          value: 'other',
        }]"
      >
        <template #label>
          {{ $trans('Common.Gender') }}
        </template>
      </Select>

      <LanguageSwitcherDropdown
        class="mb-[16px]"
      />

      <div class="flex flex-row w-full justify-end pt-[20px] lg:pt-[32px]">
        <Button
          tag="link"
          class=" lg:mb-0 mr-2"
          :big="true"
          :outline="true"
          :href="getLocalizedUrl('my-rentbird.onboarding', { step: 3 })"
        >
          <Icon name="arrow-left" />
        </Button>
        <Button
          tag="button"
          icon-right="arrow-right"
          :big="true"
          :full-width-on-mobile="true"
          class="flex-grow lg:flex-grow-0"
          @click="onClickNext"
        >
          {{ $trans('Common.Next') }}
        </Button>
      </div>
    </MyRentbirdCard>
  </div>
</template>
