<script setup>
import Icon from '@js/Components/Icon/Icon.vue'
import SearchProfileCardDistanceOrNeighbourhoodsLabel from '@js/Components/SearchProfileCard/SearchProfileCardDistanceOrNeighbourhoodsLabel.vue'
import { Link } from '@inertiajs/vue3'
import { computed } from 'vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { useMatchesPerWeek } from '@js/App/Api/MatchesPerWeek'

const props = defineProps({
  searchProfile: {
    type: Object,
    default: () => ({})
  },
  showEditButton: {
    type: Boolean,
    default: false
  },
  roundedBottom: {
    type: Boolean,
    default: false
  },
  editUrl: {
    type: String,
    default: '#'
  }
})

const searchProfileEditUrl = computed(() => {
  if (!props.showEditButton) {
    return false
  }
  if (props.editUrl && props.editUrl !== '#') {
    return props.editUrl
  }

  return getLocalizedUrl('my-rentbird.edit-search-profile', { searchProfileRandomId: props.searchProfile.serverId })
})

const { matchesPerWeek } = useMatchesPerWeek({
  value: props.searchProfile
})

const iconColor = computed(() => {
  if (matchesPerWeek?.value <= 2) {
    return 'dot-yellow'
  }

  return 'dot-green'
})
</script>
<template>
  <component
    :is="showEditButton ? Link : 'div'"
    class="bg-gray-50 px-[16px] py-[16px] rounded-t-md block transition-colors"
    :class="{
      'group hover:cursor-pointer hover:bg-gray-100': showEditButton,
      'rounded-b-md': roundedBottom
    }"
    :href="searchProfileEditUrl"
  >
    <div class="flex justify-between items-center">
      <div class="text-purple-900 font-medium text-xl mb-[4px] flex items-center">
        <Icon
          v-tippy="{
            content: $trans('Common.Active'),
            allowHTML: true
          }"
          class="pr-[8px]"
          :name="iconColor"
        />
        {{ searchProfile.city?.name || searchProfile.city_formatted }}
      </div>
      <div v-show="showEditButton">
        <Icon
          name="edit"
          icon-width="w-[20px] h-[20px]"
          class="group-hover:text-pink-500 transition-colors"
          @click="() => console.log('TODO do something here')"
        />
      </div>
    </div>
    <SearchProfileCardDistanceOrNeighbourhoodsLabel :search-profile="searchProfile" />
  </component>
</template>
