<script setup>
import { useMainStore } from '@js/App/Stores/MainStore'
import { storeToRefs } from 'pinia'
import Icon from '@js/Components/Icon/Icon.vue'
import Container from '@js/Components/Container/Container.vue'

const mainStore = useMainStore()
const { noticeMessageText } = storeToRefs(mainStore)
</script>
<template>
  <div v-if="noticeMessageText" class="bg-blue-400 text-white text-base font-medium lg:text-lg">
    <Container class="py-2">
      <div class="flex justify-between">
        <div />
        <div class="flex text-center" v-html="noticeMessageText" />
        <Icon
          class="hover:cursor-pointer hover:text-blue-base lg:relative lg:top-[2px]"
          name="cross"
          @click="noticeMessageText = ''"
        />
      </div>
    </Container>
  </div>
</template>
