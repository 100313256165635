<script setup>
import SortButton from '@js/Components/Forms/Button/ButtonSort.vue'
import { ref } from 'vue'
import {
  getQueryParameter,
  getQueryParameterBoolean,
  getQueryParameterInteger
} from '@js/App/Utils/ReadQueryParams'
import FiltersInputAllHousesPage from '@js/Components/Forms/FiltersInputAllHousesPage/FiltersInputAllHousesPage.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { Head, router } from '@inertiajs/vue3'
import {
  DEFAULT_DISTANCE,
  DEFAULT_FURNISHED,
  DEFAULT_MAX_RENT,
  DEFAULT_MIN_BEDROOMS,
  DEFAULT_MIN_SURFACE,
  DEFAULT_SORT_ASCENDING,
  DEFAULT_SORT_BY
} from '@js/App/Utils/FiltersInputAllHousesDefaultValues'
import Button from '@js/Components/Forms/Button/Button.vue'
import AllHousesResults from '@js/Components/AllHouses/AllHousesResults.vue'
import ListingPageLayout from '@js/Components/ListingPages/ListingPageLayout.vue'
import CallToActionSectionActivateAlertsSmall
  from '@js/Components/Marketing/CallToActionSection/CallToActionSectionActivateAlertsSmall.vue'
import ActivateAlertsButton from '@js/Pages/ListingPages/ActivateAlertsButton.vue'
import SegmentLinks from '@js/Components/ListingPages/SegmentLinks.vue'
import { trans } from '@js/App/Plugins/Translations'

const props = defineProps({
  pageContent: {
    type: Object,
    default: () => ({})
  },
  houses: {
    type: Object,
    default: () => ({})
  },
  isCityPage: {
    type: Boolean,
    default: false
  },
  showOtherCityButton: {
    type: Boolean,
    default: false
  },
  currentSegment: {
    type: Object,
    default: () => ({})
  },
  city: {
    type: Object,
    default: () => ({})
  },
  province: {
    type: Object,
    default: () => ({})
  },
  totalHouses: {
    type: Number,
    default: 0
  },
  links: {
    type: Array,
    default: () => ([])
  }
})

const linkGroups = props.links.reduce((acc, v) => {
  if (acc[v.group] === undefined) {
    acc[v.group] = [v]
    return acc
  }
  acc[v.group] = [...acc[v.group], v]
  return acc
}, {})

const linksOpen = ref(false)

const parseCommaSeperatedArrayQueryParameter = (queryParam) => {
  if (!queryParam) {
    return []
  }

  return queryParam.split(',')
}

const filters = ref({
  city: props.city,
  distance: getQueryParameterInteger('distance', DEFAULT_DISTANCE),
  sortBy: getQueryParameter('sort_by', DEFAULT_SORT_BY),
  sortAscending: getQueryParameterBoolean('sort_ascending', DEFAULT_SORT_ASCENDING),
  maxRent: getQueryParameterInteger('max_rent', DEFAULT_MAX_RENT),
  minBedrooms: getQueryParameterInteger('min_bedrooms', DEFAULT_MIN_BEDROOMS),
  minSurface: getQueryParameterInteger('min_surface', DEFAULT_MIN_SURFACE),
  furnished: getQueryParameter('furnished', DEFAULT_FURNISHED),
  extraWishes: parseCommaSeperatedArrayQueryParameter(getQueryParameter('extra_wishes', '')),
  neighbourhoods: parseCommaSeperatedArrayQueryParameter(getQueryParameter('neighbourhoods', ''))
})

const reloadPageWithFilters = () => {
  const url = new URL(location.protocol + '//' + location.host + location.pathname)

  if (filters.value.distance !== DEFAULT_DISTANCE) {
    url.searchParams.set('distance', `${filters.value.distance}`)
  }
  if (filters.value.sortBy !== DEFAULT_SORT_BY) {
    url.searchParams.set('sort_by', filters.value.sortBy)
  }
  if (filters.value.sortAscending !== DEFAULT_SORT_ASCENDING) {
    url.searchParams.set('sort_ascending', `${filters.value.sortAscending}`)
  }
  if (filters.value.maxRent !== DEFAULT_MAX_RENT) {
    url.searchParams.set('max_rent', `${filters.value.maxRent}`)
  }
  if (filters.value.minBedrooms !== DEFAULT_MIN_BEDROOMS) {
    url.searchParams.set('min_bedrooms', `${filters.value.minBedrooms}`)
  }
  if (filters.value.minSurface !== DEFAULT_MIN_SURFACE) {
    url.searchParams.set('min_surface', `${filters.value.minSurface}`)
  }
  if (filters.value.furnished !== DEFAULT_FURNISHED) {
    url.searchParams.set('furnished', `${filters.value.furnished}`)
  }
  if (filters.value.extraWishes?.length > 0) {
    url.searchParams.set('extra_wishes', filters.value.extraWishes.join(','))
  }
  if (filters.value.neighbourhoods?.length > 0) {
    url.searchParams.set('neighbourhoods', filters.value.neighbourhoods.join(','))
  }

  router.visit(url, {
    preserveState: true,
    preserveScroll: true
  })
}
const onClickSortButton = (field) => {
  if (filters.value.sortBy === field) {
    filters.value.sortAscending = !filters.value.sortAscending
  } else {
    filters.value.sortBy = field
  }
  reloadPageWithFilters()
}
const breadcrumbs = [
  {
    url: getLocalizedUrl('all-houses-categories'),
    name: trans('HouseListings.Breadcrumbs.AllHouses')
  },
  {
    url: getLocalizedUrl('all-houses-in-city', {
      city: props.province.slug
    }),
    name: props.province.name
  },
  {
    url: getLocalizedUrl('all-houses-in-city', {
      city: props.city.slug
    }),
    name: props.city.name
  }
]
if (props.currentSegment) {
  breadcrumbs.push({
    url: '#',
    name: props.currentSegment.name
  })
}
</script>
<template>
  <Head>
    <title>{{ pageContent.meta_title ?? $trans('Layout.MetaTitle') }}</title>
    <meta head-key="description" name="description" :content="pageContent.meta_description ?? $trans('Layout.MetaDescription')">
  </Head>
  <ListingPageLayout
    :title="pageContent.hero_heading ?? $trans('HouseListings.hero_heading')"
    :description="pageContent.hero_text ?? $trans('HouseListings.hero_text')"
    :breadcrumbs="breadcrumbs"
  >
    <div>
      <div v-if="!isCityPage" class="flex justify-start ml-1 md:ml-6 box-border gap-1 md:gap-2">
        <a
          class="inline-block text-sm bg-gray-100 text-purple-700 hover:text-pink-500 hover:bg-white px-4 py-1 rounded-t"
          :href="getLocalizedUrl('all-houses-categories')"
        >{{ $trans('HouseListings.Menu.Categories') }}</a>
        <a
          class="inline-block text-sm bg-white text-pink-500   px-4 py-1 rounded-t"
          :href="getLocalizedUrl('all-houses')"
        >{{ $trans('HouseListings.Menu.AllHouses', {amount: totalHouses}) }}</a>
      </div>

      <SegmentLinks v-if="links.length > 0" :collapsed="!linksOpen" :city="city" :links="linkGroups" @toggle:collapse="linksOpen = !linksOpen" />

      <div class="bg-white px-6 py-8 rounded-md">
        <div class="lg:flex lg:justify-between lg:mb-[32px]">
          <div class="hidden lg:flex">
            <Button
              v-if="showOtherCityButton"
              icon-right="back"
              :outline="true"
              :big="true"
              @click="() => {
                // fixme to go to the list
              }"
            >
              {{ $trans('AllHousesPage.BackToAllHousesButtonLabel') }}
            </Button>
          </div>
        </div>

        <FiltersInputAllHousesPage
          :is-city-page="isCityPage"
          :filters="filters"
          class="mb-[12px] lg:mb-[32px]"
          @update:filters="newFilters => {
            // fixme: temporary fix to disallow removing of city/province label
            if (isCityPage) {
              newFilters.city = filters.city
            }
            filters = newFilters
          }"
          @should-reload-page="reloadPageWithFilters"
        />

        <div class="flex flex-row pt-3 lg:pt-0 items-center lg:justify-end">
          <div class="hidden lg:block pr-3">
            {{ $trans('AllHousesPage.SortBy') }}
          </div>
          <SortButton
            class="pr-1"
            :icon-right="filters.sortAscending ? 'arrow-up-simple' : 'arrow-down-simple'"
            :filter-active="filters.sortBy === 'created_at'"
            @click="onClickSortButton('created_at')"
          >
            {{ $trans('MatchHistoryPage.SortByLatestLabel') }}
          </SortButton>
          <SortButton
            class="pr-1"
            :icon-right="filters.sortAscending ? 'arrow-up-simple' : 'arrow-down-simple'"
            :filter-active="filters.sortBy === 'rent'"
            @click="onClickSortButton('rent')"
          >
            {{ $trans('MatchHistoryPage.SortByRentLabel') }}
          </SortButton>
          <SortButton
            :filter-actve="false"
            :icon-right="filters.sortAscending ? 'arrow-up-simple' : 'arrow-down-simple'"
            :filter-active="filters.sortBy === 'area'"
            @click="onClickSortButton('area')"
          >
            {{ $trans('MatchHistoryPage.SortBySurfaceLabel') }}
          </SortButton>
        </div>

        <AllHousesResults :houses="props.houses" />
      </div>
    </div>
    <CallToActionSectionActivateAlertsSmall v-if="houses.data.length >= 12" />
    <div v-if="pageContent.bottom_seo_title && pageContent.bottom_seo_text" class="bg-white px-6 py-8 rounded-md">
      <p class="font-bold text-3xl mb-4" v-html="pageContent.bottom_seo_title" />
      <p class="text-lg" v-html="pageContent.bottom_seo_text" />
      <div class="inline-block mt-4 flex gap-4">
        <ActivateAlertsButton />
        <Button
          outline
          tag="a"
          :href="$getLocalizedUrl('all-houses-in-city', {
            city: city.slug
          })"
          icon-right="arrow-right"
        >
          {{ $trans('Buttons.AllHousesInPlace', {place:city.name}) }}
        </Button>
      </div>
    </div>
  </ListingPageLayout>
</template>
<style lang="scss">
.AllHousesPage {
  background: rgb(234, 234, 235);
}
</style>
