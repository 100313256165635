<script setup>
import OffboardingLayout from '@js/Components/MyRentbird/Offboarding/OffboardingLayout/OffboardingLayout.vue'
import Container from '@js/Components/Container/Container.vue'
import SmallLabel from '@js/Components/SmallLabel/SmallLabel.vue'
import { computed, ref } from 'vue'
import ReviewUsWriteReviewStep from '@js/Components/MyRentbird/Offboarding/ReviewUs/ReviewUsWriteReviewStep.vue'
import UploadSelfieStep from '@js/Components/MyRentbird/Offboarding/ReviewUs/UploadSelfieStep.vue'
import ReviewUsYourDetails from '@js/Components/MyRentbird/Offboarding/ReviewUs/ReviewUsYourDetails.vue'
import KimTextbox from '@js/Components/MyRentbird/Offboarding/PersonTextBox/PersonTextBox.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import UserReviewCard from '@js/Components/Marketing/UserReviewsSection/UserReviewCard.vue'
import CustomTransitionFade from '@js/Components/Transitions/CustomTransitionFade.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import Image from '@js/Components/Image/Image.vue'
import SelfieInstructions from '@images/selfie-instructions.png'
// import { useUser } from '@js/App/Api/User/User'

const step = ref(1)
const uploadedImages = ref([])
const { isMobile } = useIsMobile()

// const { user } = useUser()
// const isMobileAppUser = computed(
//   () => user.value.notificationChannel === 'mobile'
// )

const reviewPlatformToShow = computed(() => {
  // temp always show trustpilot to enhance trustpilot reviews
  // if (isMobileAppUser.value) {
  //   return Math.random() <= 0.5 ? 'mobile' : 'trustpilot'
  // }
  return 'trustpilot'
})

</script>
<template>
  <div>
    <OffboardingLayout
      :show-back-button="false"
      :show-trust-pilot="!isMobile"
      :show-close-button="isMobile"
    >
      <template v-if="step <= 2 && isMobile" #top-left>
        <SmallLabel>
          {{ $trans('SearchProfileWizard.Step', { step: step, total: 2 }) }}
        </SmallLabel>
      </template>
      <Container class="text-white pb-[40px] lg:pt-[70px]">
        <div class="flex flex-row">
          <div class="lg:w-1/2 lg:pr-6">
            <CustomTransitionFade :group="true">
              <div class="mb-10 text-3xl font-bold">
                <div v-if="step===1" class="mb-2">
                  {{ $trans(reviewPlatformToShow === 'trustpilot'
                    ? 'ReviewUsPage.Step1Title'
                    : 'ReviewUsPage.Step1AppStoreTitle'
                  ) }}
                </div>
                <div v-if="step===2" class="mb-2">
                  {{ $trans('ReviewUsPage.Step2Title') }}
                </div>
                <SmallLabel v-if="!isMobile" class="max-w-fit">
                  {{ $trans('SearchProfileWizard.Step', { step: step, total: 2 }) }}
                </SmallLabel>
              </div>

              <ReviewUsWriteReviewStep
                v-if="step === 1"
                :key="1"
                :platform="reviewPlatformToShow"
                @clicked:next="step++"
              />
              <UploadSelfieStep
                v-if="step === 2"
                :key="2"
                @next-step="images => {
                  uploadedImages = images;
                  step++
                }"
              />

              <ReviewUsYourDetails
                v-if="step === 3"
                :key="3"
                :images="uploadedImages"
                @next-step="step++"
              />
              <div
                v-if="step === 4"
                :key="4"
              >
                <KimTextbox>
                  <template #title>
                    {{ $trans('ReviewUsPage.FinalStepTitle') }}
                  </template>
                  {{ $trans('ReviewUsPage.FinalStepText') }}
                </KimTextbox>
                <Button
                  class="mt-[24px]"
                  :full-width-on-mobile="true"
                  :big="true"
                  icon-right="arrow-up-left"
                  tag="link"
                  :href="$getLocalizedUrl('my-rentbird.subscription')"
                >
                  {{ $trans('ReviewUsPage.BackToMyRentbird') }}
                </Button>
              </div>
            </CustomTransitionFade>
          </div>
          <div v-if="!isMobile" class="lg:w-1/2">
            <div v-if="step===1" class="flex justify-center max-w-1/2">
              <UserReviewCard
                :alternative-styling="true"
                :big-text-formatting="false"
                image="/img/people/user-review-eva.png"
                name="Eva"
                :rating="5.0"
                class="mr-3"
                trustpilot-url="https://www.trustpilot.com/reviews/647b94147198a582f0606c43"
              >
                <span v-html="$trans('Ik kreeg dagelijks veel updates en alle voorstellen vielen precies binnen mijn zoekprofiel. Erg tevreden over deze service')" />
              </UserReviewCard>

              <UserReviewCard
                :alternative-styling="true"
                :big-text-formatting="false"
                image="/img/people/user-review-bogdan.png"
                name="Bogdan Zviagintsev"
                class="mt-[40px] ml-[12px]"
                :rating="5.0"
                trustpilot-url="https://www.trustpilot.com/reviews/647b94147198a582f0606c43"
              >
                <span v-html="$trans('We hebben het best mogelijke appartement binnen ons budget kunnen huren. In een wijk waar we altijd al wilden wonen. Het stond alleen op de website van de makelaar en niet op Funda of Pararius. Het was alleen mogelijk om dit appartement te vinden op Rentbird Bedankt!')" />
              </UserReviewCard>
            </div>
            <div v-if="step===2" class="lg:min-w-3/5 w-full">
              <Image
                class="w-full"
                :src="SelfieInstructions"
              />
            </div>
          </div>
        </div>
      </Container>
    </OffboardingLayout>
  </div>
</template>
<style>
.MyRentbird-ReviewUsPage {
  background-color: rgb(40,33,75);
}
</style>
