<script setup>
import ListingPromotedPlaces from '@js/Components/ListingPages/ListingPromotedPlaces.vue'
import ListingPromotedProvinces from '@js/Components/ListingPages/ListingPromotedProvinces.vue'
import CallToActionSectionActivateAlertsLarge
  from '@js/Components/Marketing/CallToActionSection/CallToActionSectionActivateAlertsLarge.vue'
import CallToActionSectionHowItWorksSmall
  from '@js/Components/Marketing/CallToActionSection/CallToActionSectionHowItWorksSmall.vue'
import ListingPageLayout from '@js/Components/ListingPages/ListingPageLayout.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'

const props = defineProps({
  provinces: {
    type: Array,
    default: () => ([])
  },
  places: {
    type: Array,
    default: () => ([])
  },
  totalHouses: {
    type: Number,
    default: 0
  }
})

</script>
<template>
  <ListingPageLayout
    :title="$trans('HouseListings.HeroTitle')"
    :sub-title="$trans('HouseListings.HeroSubTitle')"
    :description="$trans('HouseListings.HeroDescription')"
    :breadcrumbs="null"
  >
    <div>
      <div class="flex justify-start ml-1 md:ml-6 box-border gap-1 md:gap-2">
        <a
          class="inline-block text-sm bg-white text-pink-500 px-4 py-1 rounded-t"
          :href="getLocalizedUrl('all-houses-categories')"
        >{{ $trans('HouseListings.Menu.Categories') }}</a>
        <a
          class="inline-block text-sm bg-gray-100 text-purple-700 hover:text-pink-500 hover:bg-white  px-4 py-1 rounded-t"
          :href="getLocalizedUrl('all-houses')"
        >{{ $trans('HouseListings.Menu.AllHouses', { amount: props.totalHouses }) }}</a>
      </div>

      <div class="bg-white px-6 py-4 rounded-md">
        <h1 class="text-2xl lg:text-4xl font-medium text-purple-900 my-2 mb-8">
          {{ $trans('HouseListings.PromotedCityHeading') }}
        </h1>
        <ListingPromotedPlaces :places="props.places" />
      </div>
    </div>
    <CallToActionSectionActivateAlertsLarge />
    <ListingPromotedProvinces :provinces="props.provinces" />
    <CallToActionSectionHowItWorksSmall />
    <!--          <CallToActionSectionActivateAlertsSmall/>-->
  </ListingPageLayout>
</template>
<style lang="scss" scoped>
.place_card {
  aspect-ratio: 9 / 16;
}
</style>
