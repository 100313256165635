import { defineStore } from 'pinia'
import { usePage } from '@inertiajs/vue3'

// Note: this store is manually cleared on an Inertia page load in storesSetup.js
export const useMainStore = defineStore('main', {
  state: () => {
    const page = usePage()

    return {
      openModals: [],
      fadingOutModals: [],
      isFullScreenInputActive: false,

      numberOfItemsThatAreLoading: 0,
      showLoadingSpinnerInstantly: false,
      loadingSpinnerCustomText: null,
      locale: page.props.locale,

      successMessageText: '',
      noticeMessageText: '',
      videoToShowInModal: ''
    }
  },

  actions: {
    closeModal (modalId) {
      if (!this.openModals.includes(modalId)) {
        return
      }
      this.fadingOutModals.push(modalId)

      // After 500ms when the fade out animation is finished, really remove the modal.
      setTimeout(() => {
        this.fadingOutModals = this.fadingOutModals.filter(item => item !== modalId)
        this.openModals = this.openModals.filter(item => item !== modalId)
      }, 500)
    },
    openModal (modalId) {
      if (this.openModals.includes(modalId)) {
        return
      }
      this.openModals = [...this.openModals, modalId]
    },
    toggleModal (modalId) {
      if (this.openModals.includes(modalId)) {
        this.closeModal(modalId)
        return
      }
      this.openModal(modalId)
    },
    setIsFullScreenInputActive (newValue) {
      this.isFullScreenInputActive = newValue
    },
    startLoading (showLoadingSpinnerInstantly = false, customText = null) {
      this.showLoadingSpinnerInstantly = showLoadingSpinnerInstantly
      if (customText) {
        this.loadingSpinnerCustomText = customText
      }
      this.numberOfItemsThatAreLoading++
    },
    stopLoading (resetText = false) {
      this.numberOfItemsThatAreLoading--
      if (resetText) {
        this.loadingSpinnerCustomText = null
      }
    }
  },

  getters: {
    isLoading: state => state.numberOfItemsThatAreLoading > 0
  }
})
