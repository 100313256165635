//
// This file is the starting point for the client side Javascript
//
import '@sass/app.scss'
import '@morev/vue-transitions/styles'
import Slider from '@vueform/slider'
import { createApp, h } from 'vue'
import { createInertiaApp, router } from '@inertiajs/vue3'
import translationsPlugin, { trans } from '@js/App/Plugins/Translations'
import localizedUrlsPlugin from '@js/App/Plugins/LocalizedUrls'
import formatNumberPlugin from '@js/App/Plugins/Formatting'
import modalsPlugin from '@js/App/Plugins/Modals'
import { addTrackingScriptsIfProduction } from '@js/App/Performance/LazyScripts'
import { createPinia } from 'pinia'
import { setupPageViewTracking } from '@js/App/Tracking/Tracking'
import { setupResetStoresOnNavigation } from '@js/App/Stores/StoresSetup'
import VueTippy from 'vue-tippy'
import Layout from '@js/Pages/Layout.vue'
import VueTransitions from '@morev/vue-transitions'
import * as Sentry from '@sentry/vue'
import { getFromCache, putInCache } from '@js/App/Performance/LocalCache'
import { isSSR } from '@js/App/Utils/Environment'
import SSRLayout from '@js/Pages/SSRLayout.vue'

const onAppLoadedAndReady = () => {
  addTrackingScriptsIfProduction()
  setupPageViewTracking()
  setupResetStoresOnNavigation()

  router.on('navigate', () => {
    console.log('[DEBUG] Tracking history')
    const history = getFromCache('debug_track_history') || []

    history.push({
      url: window.location.href,
      date: new Date()
    })

    putInCache('debug_track_history', history)
  })
}

if (!isSSR()) {
  // This snipped makes sure that when clicking the back button we reload components and
  // don't use the cached state.
  window.addEventListener('popstate', (event) => {
    event.stopImmediatePropagation()

    router.reload({
      preserveState: false,
      preserveScroll: false,
      replace: true
    })
  })
}

setTimeout(() => {
  createInertiaApp({
    progress: {
      delay: 0,
      color: '#E71C77',
      includeCSS: true,
      showSpinner: false
    },
    title: title => title || trans('Layout.MetaTitle'),
    resolve: (name) => {
      const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
      const page = pages[`./Pages/${name}.vue`]
      if (!page.default.layout) {
        page.default.layout = (h, page) => (h(SSRLayout, () => h(Layout, () => page)))
      }
      return page
    },
    setup ({ el, App, props, plugin }) {
      const pinia = createPinia()

      const app = createApp({ render: () => h(App, props) })
        .use(plugin)
        .use(pinia)
        .use(translationsPlugin)
        .use(localizedUrlsPlugin)
        .use(formatNumberPlugin)
        .use(modalsPlugin)
        .use(VueTippy)
        .use(VueTransitions)
        .component('Slider', Slider)

      Sentry.init({
        app,
        dsn: 'https://f6fac2680cf633235fe0ef3d438d5553@o1346347.ingest.us.sentry.io/4506359496245248',
        enabled: props.initialPage.props.environment === 'production',
        tracesSampleRate: 0.5,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
      })

      app.mount(el)

      onAppLoadedAndReady()
    }
  })
}, 1)
