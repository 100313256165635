<script setup>
import ModalRoot from '@js/Components/Modals/ModalRoot.vue'
import LoadingOverlay from '@js/Components/Forms/LoadingOverlay/LoadingOverlay.vue'
import { isWindows } from '@js/App/Utils/BrowserDetection'
import { onMounted, ref } from 'vue'
import Checkout from '@js/Components/PricingCard/Checkout/Checkout.vue'
import HeaderWithLogoAndTrustpilot from '@js/Components/HeaderWithLogoAndTrustpilot/HeaderWithLogoAndTrustpilot.vue'
import { router, usePage } from '@inertiajs/vue3'
import { getQueryParameterBoolean } from '@js/App/Utils/ReadQueryParams'
import MainTopBarMessage from '@js/Components/MainTopBarMessage/MainTopBarMessage.vue'
import { showCreateSearchProfileWizard } from '@js/App/SearchProfile'

const checkIsOnPageThatShouldLoadCheckoutComponent = () => checkIsOnCheckoutPage() ||
    window.location.href.includes('/upgrade')

const checkIsOnCheckoutPage = () => window.location.href.includes('/checkout') && window.location.href.includes('plan=')

const isOnPageThatShouldLoadCheckoutComponent = ref(checkIsOnPageThatShouldLoadCheckoutComponent())
const isOnCheckoutPage = ref(checkIsOnCheckoutPage())
const hasMountedCheckoutComponent = ref(isOnPageThatShouldLoadCheckoutComponent.value)

const onRouterNavigate = () => {
  document.body.classList.remove(
    ...document.body.classList
  )
  document.body.classList.add(page.component.replaceAll('/', '-'))
  setIsOnPageThatShouldLoadCheckoutComponent()
}

router.on('navigate', onRouterNavigate)

const setIsOnPageThatShouldLoadCheckoutComponent = () => {
  isOnPageThatShouldLoadCheckoutComponent.value = checkIsOnPageThatShouldLoadCheckoutComponent()
  if (isOnPageThatShouldLoadCheckoutComponent.value) { hasMountedCheckoutComponent.value = true }

  isOnCheckoutPage.value = checkIsOnCheckoutPage()
}

const checkIfOnCheckoutPageInterval = ref(null)
onMounted(() => {
  if (!checkIfOnCheckoutPageInterval.value) {
    checkIfOnCheckoutPageInterval.value = setInterval(() => {
      setIsOnPageThatShouldLoadCheckoutComponent()
    }, 250)
  }

  const shouldOpenWizard = getQueryParameterBoolean('showWizard', false)
  if (shouldOpenWizard) {
    setTimeout(() => {
      showCreateSearchProfileWizard()
    }, 250)
  }
})

const page = usePage()
</script>
<template>
  <div
    :class="{
      'os-windows': isWindows(),
      'os-other': !isWindows(),
      [`${page.component.replaceAll('/', '-')}`]: true,
    }"
    class="min-h-full"
  >
    <MainTopBarMessage v-if="false" />
    <slot />

    <!--
    Why is this here?
    The layout is persistent. So on page loads these components are not re-rendered. This means that we can preload
    the Checkout component and Stripe elements. If we don't do this the checkout page is super slow (5s to load stripe).
    This way it's preloaded causing there to be no friction
    -->
    <div
      v-if="hasMountedCheckoutComponent || isOnPageThatShouldLoadCheckoutComponent"
      v-show="isOnCheckoutPage"
      class="h-full flex flex-col"
    >
      <HeaderWithLogoAndTrustpilot />
      <Checkout />
    </div>

    <ModalRoot />
    <LoadingOverlay />
  </div>
</template>
