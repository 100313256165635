<script setup>
import { ref, onMounted, watch } from 'vue'
import { useUser } from '@js/App/Api/User/User'
import Icon from '@js/Components/Icon/Icon.vue'

const { user } = useUser()
const showTopBar = ref(true)

onMounted(() => {
  const savedTopBarState = localStorage.getItem('showTopBar')
  if (savedTopBarState !== null && user.value.isLoggedIn) {
    showTopBar.value = JSON.parse(savedTopBarState)
  }
})

const hideTopBar = (e) => {
  if (user.value.isLoggedIn) {
    showTopBar.value = false
    localStorage.setItem('showTopBar', JSON.stringify(false))
  }
  e.preventDefault()
}

watch(showTopBar, (newValue) => {
  localStorage.setItem('showTopBar', JSON.stringify(newValue))
})
</script>

<template>
  <a
    v-if="showTopBar"
    :href="$getLocalizedUrl('rentbird-plus')"
    :class="{
      [`bg-blue-base text-white text-center py-2 font-medium text-sm md:text-base block flex justify-center items-center`]: true,
      'cursor-pointer': user.isLoggedIn
    }"
  >
    <span v-html="$trans('TopBars.RentbirdPlus.Title')" />
    <Icon
      v-if="user.isLoggedIn"
      class="md:ml-4 relative top-[-1px]"
      name="cross"
      @click="hideTopBar"
    />
  </a>
</template>
