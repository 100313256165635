<script setup>
import SearchProfileCardLabel from '@js/Components/SearchProfileCard/SearchProfileCardLabel.vue'
import Icon from '@js/Components/Icon/Icon.vue'
import { capitalizeFirstLetter } from '@js/App/Utils/StringFormatting'
import { getCurrency } from '@js/App/Utils/CountryAndLocale'
import { RENTAL_CHANGE_SUPER } from '@js/Components/HouseCards/RentalChanceLabel/RentalChanceLabel'
import RentalChanceLabel from '@js/Components/HouseCards/RentalChanceLabel/RentalChanceLabel.vue'
import { isSSR } from '@js/App/Utils/Environment'

const props = defineProps({
  image: String,
  name: String,
  rentalAgent: String,
  foundAtFormatted: String,
  city: String,
  bedrooms: Number,
  area: Number,
  rent: Number,
  url: String,
  rentalChance: {
    type: String,
    default: RENTAL_CHANGE_SUPER
  }
})

const emit = defineEmits('openModal')

const onHouseClick = () => {
  emit('openModal')
  if (!isSSR()) {
    window.open(props.url, '_blank')?.focus()
  }
}
</script>
<template>
  <component
    :is="'a'"
    class="relative group hover:cursor-pointer transition-colors flex flex-col"
    :href="url"
    target="_blank"
    @click.prevent="onHouseClick"
  >
    <img
      :src="image"
      alt="house image"
      class="rounded-t-md w-full h-[175px] lg:h-[235px]"
    >
    <RentalChanceLabel
      class="absolute top-[8px] right-[8px]"
      :rental-chance="rentalChance"
    />

    <div
      class="flex-grow bg-blue-50 group-hover:bg-blue-200 transition-colors px-[8px] py-[8px] rounded-b-md lg:px-[16px] lg:py-[16px] w-full"
    >
      <h4 class="text-base text-purple-900 font-medium lg:text-xl lg:mb-[2px]">
        {{ name }}
      </h4>
      <p class="text-sm text-gray-800 lg:mb-[2px]">
        {{ foundAtFormatted }}
        <svg
          class="inline"
          xmlns="http://www.w3.org/2000/svg"
          width="4"
          height="4"
          viewBox="0 0 4 4"
          fill="none"
        >
          <circle cx="2" cy="2" r="2" fill="#3A3B3B" />
        </svg>
        {{ rentalAgent }}
      </p>

      <div class="flex flex-wrap">
        <SearchProfileCardLabel class="mr-[4px] mt-[4px]">
          <div class="flex">
            <Icon name="location" icon-width="w-[20px] h-[20px] lg:w-[24px] lg:h-[24px]" class="mr-[4px] relative top-[1px]" />
            <span class="text-base text-purple-900">{{ capitalizeFirstLetter(city) }}</span>
          </div>
        </SearchProfileCardLabel>

        <SearchProfileCardLabel class="mr-[4px] mt-[4px]">
          <div class="flex">
            <Icon name="bed-alternative" icon-width="w-[20px] h-[20px] lg:w-[24px] lg:h-[24px]" class="mr-[4px] relative top-[1px]" />
            <span class="text-base text-purple-900">{{ bedrooms || '?' }}</span>
          </div>
        </SearchProfileCardLabel>

        <SearchProfileCardLabel class="mr-[4px] mt-[4px]">
          <div class="flex">
            <Icon name="ruler" icon-width="w-[20px] h-[20px] lg:w-[24px] lg:h-[24px]" class="mr-[4px] relative top-[1px]" />
            <span class="text-base text-purple-900">{{ area || '? ' }}m<sup>2</sup></span>
          </div>
        </SearchProfileCardLabel>

        <SearchProfileCardLabel class="mr-[4px] mt-[4px]">
          <div class="flex">
            <Icon name="tag" icon-width="w-[20px] h-[20px] lg:w-[24px] lg:h-[24px]" class="mr-[4px] relative top-[2px]" />
            <span class="text-base text-purple-900">{{ getCurrency() }}{{ rent }}</span>
          </div>
        </SearchProfileCardLabel>
      </div>
    </div>
  </component>
</template>
