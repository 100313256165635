<script setup>
import Pagination from '@js/Components/Pagination/Pagination.vue'
import CallToActionSectionActivateAlertsLarge
  from '@js/Components/Marketing/CallToActionSection/CallToActionSectionActivateAlertsLarge.vue'
import AllHousesHouseCard from '@js/Components/HouseCards/AllHousesHouseCard.vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import CallToActionSectionHowItWorksSmall
  from '@js/Components/Marketing/CallToActionSection/CallToActionSectionHowItWorksSmall.vue'
import { ref } from 'vue'
import AllHousesPageUpgradeNowModal
  from '@js/Components/Modals/AllHousesPageUpgradeNowModal/AllHousesPageUpgradeNowModal.vue'

defineProps({
  houses: {
    type: Object,
    default: () => ({})
  },
  shouldNavigateOnPagination: {
    type: Boolean,
    default: true
  }
})

const upgradeModalIsOpen = ref(false)
const houseToShowInModal = ref({})

defineEmits(['setPage'])

</script>
<template>
  <div v-if="houses?.data" class="mt-3 lg:mt-4 lg:flex lg:flex-wrap justify-center lg:-mx-[12px]">
    <AllHousesPageUpgradeNowModal
      v-if="upgradeModalIsOpen"
      :name="houseToShowInModal.name"
      :rental-agent="houseToShowInModal.rental_agent_name"
      :found-at-formatted="houseToShowInModal.created_at_formatted_diff"
      :image="houseToShowInModal.image_url"
      :rental-chance="houseToShowInModal.rental_chance"
      @close-modal="upgradeModalIsOpen = false"
    />

    <AllHousesHouseCard
      v-for="item of houses.data.slice(0,6)"
      :key="item.random_id"
      :rental-chance="item.rental_chance"
      class="w-[100%] max-w-[450px] mx-auto lg:mx-0 lg:max-w-auto lg:w-[50%] xl:w-[33%] lg:px-[12px] mb-[24px]"
      :name="item.name"
      :image="item.image_url"
      :url="item.detail_url"
      :city="item.city"
      :bedrooms="item.bedrooms"
      :area="item.area"
      :rent="item.rent"
      :found-at-formatted="item.created_at_formatted_diff"
      :rental-agent="item.rental_agent_name"
      :show-viewed-label="false"
      :has-viewed="item.has_viewed"
      :is-historic="item.is_historic"
      @open-modal="() => {
        houseToShowInModal = item
        upgradeModalIsOpen = true
      }"
    />

    <CallToActionSectionActivateAlertsLarge v-if="houses.data.length >= 6" class="lg:mx-[15px] w-full mb-6" />

    <AllHousesHouseCard
      v-for="item of houses.data.slice(6,12)"
      :key="item.random_id"
      :rental-chance="item.rental_chance"
      class="w-[100%] max-w-[450px] mx-auto lg:mx-0 lg:max-w-auto lg:w-[50%] xl:w-[33%] lg:px-[12px] mb-[24px]"
      :name="item.name"
      :image="item.image_url"
      :url="item.detail_url"
      :city="item.city"
      :bedrooms="item.bedrooms"
      :area="item.area"
      :rent="item.rent"
      :found-at-formatted="item.created_at_formatted_diff"
      :rental-agent="item.rental_agent_name"
      :show-viewed-label="false"
      :has-viewed="item.has_viewed"
      :is-historic="item.is_historic"
      @open-modal="() => {
        houseToShowInModal = item
        upgradeModalIsOpen = true
      }"
    />

    <CallToActionSectionHowItWorksSmall v-if="houses.data.length >= 7" class="lg:mx-[15px] mb-6" />

    <AllHousesHouseCard
      v-for="item of houses.data.slice(12,18)"
      :key="item.random_id"
      :rental-chance="item.rental_chance"
      class="w-[100%] max-w-[450px] mx-auto lg:mx-0 lg:max-w-auto lg:w-[50%] xl:w-[33%] lg:px-[12px] mb-[24px]"
      :name="item.name"
      :image="item.image_url"
      :url="item.detail_url"
      :city="item.city"
      :bedrooms="item.bedrooms"
      :area="item.area"
      :rent="item.rent"
      :found-at-formatted="item.created_at_formatted_diff"
      :rental-agent="item.rental_agent_name"
      :show-viewed-label="false"
      :has-viewed="item.has_viewed"
      :is-historic="item.is_historic"
      @open-modal="() => {
        houseToShowInModal = item
        upgradeModalIsOpen = true
      }"
    />

    <InfoMessageBox
      v-if="houses.data.length === 0"
      color="pink"
      class="text-center w-full block my-4 text-base lg:text-lg text-gray-900"
    >
      <div v-html="$trans('AllHousesPage.NoSearchResults')" />
    </InfoMessageBox>
  </div>
  <Pagination
    v-if="houses.data.length > 9 || houses?.last_page > 1"
    :should-navigate-on-pagination="shouldNavigateOnPagination"
    class="mt-[32px]"
    :base-url="''"
    :current-page="houses?.current_page"
    :last-page="houses?.last_page"
    @set-page="newPage => $emit('setPage', newPage)"
  />
</template>
