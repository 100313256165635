<script setup>

</script>

<template>
  <div class="bg-transparent w-full">
    <div class="">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 39" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1440 0.353271V0H0V0.353271C0 0.353271 435.823 39 717 39C998.177 39 1440 0.353271 1440 0.353271Z" fill="#28214B" />
      </svg>
    </div>
  </div>
</template>

<style scoped>
svg {
  width: 100vw;
}
</style>
