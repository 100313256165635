<script setup>
import SearchProfilesCard from '@js/Components/MyRentbird/SearchProfilesOverviewCard/SearchProfilesOverviewCard.vue'
import UserProfileStatsCard from '@js/Components/MyRentbird/UserProfileStatsCard/UserProfileStatsCard.vue'
import MatchHistoryCard from '@js/Components/MyRentbird/MatchHistoryOverviewCard/MatchHistoryOverviewCard.vue'
import FreeAccountMessage from '@js/Components/MyRentbird/FreeAccountMessage/FreeAccountMessage.vue'
import MyRentbirdLayout from '@js/Components/MyRentbird/MyRentbirdLayout/MyRentbirdLayout.vue'
import ResponseLetterCard from '@js/Components/MyRentbird/ResponseLetter/ResponseLetterCard.vue'
import RentBirdPlusCard from '@js/Components/MyRentbird/RentBirdPlus/RentBirdPlusCard.vue'
import SearchBuddyCard from '@js/Components/MyRentbird/SearchBuddyCard/SearchBuddyCard.vue'
import AddOurNumberMessage from '@js/Components/MyRentbird/AddOurNumberMessage/AddOurNumberMessage.vue'
import ModalInstallOurApp from '@js/Components/MyRentbird/ModalInstallOurApp/ModalInstallOurApp.vue'
import { isSSR } from '@js/App/Utils/Environment'
import { useUser } from '@js/App/Api/User/User'
import VerhuisgidsCard from '@js/Components/MyRentbird/Verhuisgids/Card/VerhuisgidsCard.vue'
import { useMainStore } from '@js/App/Stores/MainStore'
import { onMounted } from 'vue'
import { trans } from '@js/App/Plugins/Translations'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'

const { user, userActivePayment } = useUser()

const mainStore = useMainStore()

onMounted(() => {
  setTimeout(() => {
    if (userActivePayment.value && userActivePayment.value.subscription_ends_in_days <= 3) {
      mainStore.noticeMessageText = trans('NoticeMessageText.SubscriptionEndsInDays', {
        days: userActivePayment.value.subscription_ends_in_days,
        date: userActivePayment.value.subscription_ends_at_formatted,
        url: getLocalizedUrl('my-rentbird.subscription')
      })
    }
  }, 0)
})
</script>
<template>
  <MyRentbirdLayout>
    <AddOurNumberMessage v-if="user.shouldShowAddOurWhatsappNumberMessage" class="mb-4 lg:mb-6" />
    <ModalInstallOurApp v-if="!isSSR()" />

    <div class="flex flex-wrap">
      <div class="w-full lg:w-2/3 lg:pr-3 pb-4 lg:pb-0 flex flex-col">
        <FreeAccountMessage v-if="!user.hasActiveSubscription" class="mb-4 lg:mb-6" />
        <VerhuisgidsCard v-if="user.hasActiveSubscription" class="mb-4 lg:mb-6" />
        <UserProfileStatsCard v-if="user.hasActiveSubscription" class="mb-4 lg:mb-6 order-last lg:order-none" />
        <MatchHistoryCard class="mb-4 lg:mb-6" />
      </div>
      <div class="w-full lg:w-1/3 lg:pl-3 pb-4 lg:pb-0">
        <SearchProfilesCard class="mb-4 lg:mb-6" />
        <ResponseLetterCard v-if="user.hasActiveSubscription" class="mb-4 lg:mb-6" />
        <SearchBuddyCard v-if="user.hasActiveSubscription" class="mb-4 lg:mb-6" />
        <RentBirdPlusCard class="mb-4 lg:mb-6" />
      </div>
    </div>
  </MyRentbirdLayout>
</template>
<style>
.MyRentbird-HomePage {
  background: rgb(234, 234, 235);
}
</style>
