<script setup>
import NavMenu from '@js/Components/NavMenu/NavMenu.vue'
import TrustpilotLogo from '@images/trustpilot-logo-white.svg'
import TrustpilotStars from '@js/Components/Trustpilot/TrustpilotStars.vue'
import { usePage } from '@inertiajs/vue3'
import Image from '@js/Components/Image/Image.vue'
import SectionDivider from '@js/Components/Marketing/SectionDivider/SectionDivider.vue'
import Container from '@js/Components/Container/Container.vue'
import { useUser } from '@js/App/Api/User/User'

const page = usePage()
const numberOfReviews = page.props?.trustpilot?.numberOfReviews
const { user } = useUser()
</script>
<template>
  <div class="bg-purple-700 text-white lg:mb-0">
    <Container class="pb-8 pt-4 lg:pt-8 lg:pb-16">
      <NavMenu />
    </Container>

    <Container>
      <div class="mt-1 pb-[140px] lg:pb-[92px]">
        <div class="block lg:flex">
          <div class="w-full lg:mr-3 mb-6 lg:mb-0 flex items-center justify-center text-center">
            <div class="flex flex-col lg:block">
              <h4 class="text-white text-base lg:text-2xl lg:mb-3">
                {{ $trans('PricingHeroSection.SubTitle') }}
              </h4>
              <h1 class="text-white text-3xl font-medium lg:text-6xl lg:text-[64px]" v-html="user.isLoggedIn ? $trans('PricingHeroSection.TitleUser', { name: user.firstNameFormatted }) : $trans('PricingHeroSection.Title')" />

              <div class="flex mt-3 lg:mt-4 items-center justify-center">
                <p class="text-base relative top-[-3px] lg:top-0 leading-9 lg:text-xl">
                  <Image class="inline relative top-[-2px] pr-4" :src="TrustpilotLogo" />
                  <TrustpilotStars class="w-[132px] inline relative top-[-2px]" :rating="page.props.trustpilot.rating" />
                  <span class="font-medium pl-3">{{ $formatNumber(page.props.trustpilot.rating, 1) }}</span>
                </p><div
                  class="hidden lg:block text-sm text-white pl-4"
                  v-html="$trans('TrustpilotBox.BasedOn', {
                    url: 'https://nl.trustpilot.com/review/rentbird.nl',
                    number: numberOfReviews
                  })"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
    <SectionDivider class="mb-24" />
  </div>
</template>
