import { isSSR } from '@js/App/Utils/Environment'

export const getQueryParameter = (key, defaultValue = undefined) => {
  // We don't support the window keyword on SSR
  if (isSSR()) { return defaultValue }

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  })

  const value = params[key]
  if (value === null) {
    return defaultValue
  }

  return value
}

export const getQueryParameterBoolean = (key, defaultValue = false) => {
  const param = getQueryParameter(key, defaultValue)
  if (param === true || param === 1 || param === '1' || param === 'true') {
    return true
  }

  return false
}

export const getQueryParameterInteger = (key, defaultValue = null) => {
  const param = getQueryParameter(key, defaultValue)
  if (param === defaultValue) {
    return defaultValue
  }

  return parseInt(param)
}

export const getFullUrl = () => {
  if (isSSR()) { return '' }

  return window.location.href
}

export const getFullUrlWithoutQueryString = () => {
  const href = getFullUrl()
  if (href?.includes('?')) {
    return href.split('?')[0]
  }
  return href
}
