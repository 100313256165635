<script setup>
import { useUser } from '@js/App/Api/User/User'
import { showCreateSearchProfileWizard } from '@js/App/SearchProfile'
import { router } from '@inertiajs/vue3'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import Button from '@js/Components/Forms/Button/Button.vue'

const { user } = useUser()
const isLoggedIn = user.value.isLoggedIn
const hasActiveSubscription = user.hasActiveSubscription

const onClickedButton = () => {
  if (!isLoggedIn) {
    return showCreateSearchProfileWizard()
  }

  if (hasActiveSubscription) {
    router.visit(getLocalizedUrl('my-rentbird.home'))
  } else {
    router.visit(getLocalizedUrl('upgrade'))
  }
}
</script>

<template>
  <Button
    :href="$getLocalizedUrl('upgrade')"
    :full-width-on-desktop="true"
    :full-width-on-mobile="true"
    icon-right="bell-alert"
    @click="onClickedButton"
  >
    {{ $trans('CallToActionSectionActivateAlertsLarge.ActivateAlerts') }}
  </Button>
</template>
