<script setup>
import { Head, router, usePage } from '@inertiajs/vue3'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { onMounted, onUnmounted, ref } from 'vue'

const canonicalUrl = ref('')
const page = usePage()

const calcUrl = () => {
  const url = new URL(page.props.fullUrl)
  if (url?.href.includes('/welcome')) {
    canonicalUrl.value = getLocalizedUrl('welcome')
  } else {
    const newUrl = new URL(url.href)
    // clear search params
    newUrl.search = ''

    // include page= query param if set
    if (url?.searchParams.has('page')) {
      newUrl.searchParams.set('page', url.searchParams.get('page'))
    }
    canonicalUrl.value = newUrl.toString()
  }
}

calcUrl()

let removeNavListener
onMounted(() => {
  removeNavListener = router.on('navigate', calcUrl)
})
onUnmounted(() => {
  removeNavListener?.()
})
</script>

<template>
  <Head>
    <link head-key="canonical" rel="canonical" :href="canonicalUrl">
  </Head>
</template>
