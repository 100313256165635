<script setup>
import { onMounted, ref } from 'vue'
import Icon from '@js/Components/Icon/Icon.vue'

defineProps({
  isOpen: Boolean,

  showTopBar: {
    type: Boolean,
    default: true
  },
  padding: {
    type: Boolean,
    default: true
  },

  // These props are used for sliding in and out animations
  justGotClosed: {
    type: Boolean,
    default: false
  }
})

defineOptions({
  inheritAttrs: false
})

const justGotMounted = ref(true)
onMounted(() => {
  justGotMounted.value = true
  setTimeout(() => { justGotMounted.value = false }, 1)
})

defineEmits(['close'])

</script>
<template>
  <div
    class="relative z-70 "
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <!-- This is the dark background on deskotp -->
    <div
      class="fixed inset-0 bg-black bg-opacity-50 transition-all duration-200"
      :class="{
        'bg-opacity-0': justGotMounted || justGotClosed,
      }"
    />

    <!-- This is the modal -->
    <div class="fixed inset-0 flex justify-center items-center lg:py-10">
      <div
        v-bind="$attrs"
        class="bg-purple-700 overflow-y-auto h-auto max-h-[90vh] w-full lg:max-h-full lg:max-w-full lg:rounded-md transition-transform duration-500 text-white lg:w-[400px] mr-[16px] ml-[16px] rounded-md"
        :class="{
          'translate-y-vscreen': justGotMounted,
          'scroll-down': justGotClosed,
          'p-4 lg:p-6': padding
        }"
      >
        <div v-if="showTopBar" class="flex justify-end">
          <Icon name="cross" class="text-white transition-colors hover:cursor-pointer" @click="$emit('close')" />
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>
