<script setup>
import { ref, onMounted } from 'vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  registerables
// eslint-disable-next-line import/namespace
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
  ChoroplethController,
  BubbleMapController,
  GeoFeature,
  ProjectionScale,
  ColorScale,
  SizeScale
} from 'chartjs-chart-geo'
import TheNetherlands from './the-netherlands.json'
import TheNetherlandsProvinces from './the-netherlands-provinces.json'

Chart.register(
  ChoroplethController,
  BubbleMapController,
  GeoFeature,
  ProjectionScale,
  ColorScale,
  SizeScale,
  ...registerables
)

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  isEmbed: {
    type: Boolean,
    default: true
  }
})

// copied from frontend/js/App/Utils/BrowserDetection.js to prevent loading whole app in this component
const useIsMobile = () => {
  const isMobile = ref(checkIfIsMobile())

  if (typeof window !== 'undefined') {
    window.addEventListener('resize', (event) => {
      isMobile.value = checkIfIsMobile()
    })
  }

  return { isMobile }
}
const checkIfIsMobile = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < 769
  }

  return false
}

const { isMobile } = useIsMobile()
const generalChart = ref(null)

const copyEmbedCode = () => {
  navigator.clipboard.writeText(
    `<iframe src="https://rentbird.nl/nl/huursentiment-monitor/grafiek/${props.data.id}" width="100%" height="100%" frameborder="0"></iframe>`
  )
}

onMounted(() => {
  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    ChartDataLabels
  )

  switch (props.data.type) {
    case 'doughnut':
      Chart.defaults.backgroundColor = ['#E71C77', '#e59abc', '#F6A6CA', '#FBD4E6', '#8E0F48', '#320519']
      break
    case 'bar':
    case 'bubbleMap':
      Chart.defaults.backgroundColor = ['#E71C77']
      break
    default:
      Chart.defaults.backgroundColor = [
        '#00A2CE',
        '#3B316E',
        '#FFB017',
        '#0FBA80',
        '#E71C77',
        '#87E5FF',
        '#968BCB',
        '#F6A6CA',
        '#FFF2D9',
        '#00627C',
        '#28214B',
        '#8E0F48',
        '#555758'
      ]
  }
  Chart.defaults.font.size = 12
  Chart.defaults.font.family = 'ABCNormal, sans-serif'
  if (props.data.type && Object.keys(props.data).length) {
    let datalabelsConfig = {}
    if (props.data.hideTooltips && props.data.hideTooltips === true) {
      datalabelsConfig = {
        display: false
      }
    } else if (props.data.type === 'doughnut') {
      datalabelsConfig = {
        color: '#E71C77',
        formatter: function (value, context) {
          return `${value}%`
        },
        textAlign: 'center',
        backgroundColor: '#FBD4E6',
        borderRadius: 5
      }
    } else if (props.data.label && props.data.label === 'percentage' && props.data.type === 'bar') {
      datalabelsConfig = {
        color: '#fff',
        textAlign: 'start',
        formatter: (value, ctx) => {
          return value + '%'
        }
      }
    } else {
      datalabelsConfig = {
        color: '#fff',
        textAlign: 'start'
      }
    }

    let data = props.data
    if (props.data.type === 'bubbleMap') {
      data = {
        labels: props.data.labels,
        datasets: [
          {
            label: 'States',
            outline: TheNetherlandsProvinces.features,
            data: props.data.datasets
          }
        ]
      }
    } else if (props.data.type === 'choropleth') {
      data = {
        labels: props.data.labels,
        datasets: [
          {
            label: 'States',
            outline: TheNetherlands,
            data: TheNetherlandsProvinces.features.map((d, i) => ({
              feature: d,
              value: props.data.datasets[i].value
            }))
          }
        ]
      }
    }

    const myChart = new Chart(generalChart.value, {
      type: props.data.type,
      data,
      options:
        props.data.type === 'bubbleMap' || props.data.type === 'choropleth'
          ? {
              aspectRatio: isMobile.value === true ? 1 : undefined,
              plugins: {
                legend: {
                  display: false
                },
                datalabels:
                  props.data.type === 'choropleth'
                    ? {
                        align: 'top',
                        color: '#fff',
                        formatter: (v) => {
                          // return v.feature.properties.name
                          return ''
                        }
                      }
                    : false
              },
              scales: {
                projection: {
                  axis: 'x',
                  projection: 'mercator'
                },
                color:
                  props.data.type === 'choropleth'
                    ? {
                        axis: 'x',
                        interpolate: (v) => {
                          return ['#FDECF3', '#FBD4E6', '#F6A6CA', '#e59abc', '#E71C77'][Math.floor(v * 5)]
                        },
                        legend: {
                          position: 'bottom-right',
                          align: 'right'
                        }
                      }
                    : undefined,
                size:
                  props.data.type === 'bubbleMap'
                    ? {
                        axis: 'x',
                        size: [1, 20]
                      }
                    : undefined
              }
            }
          : {
              maintainAspectRatio: props.data.type !== 'doughnut',
              responsive: true,
              aspectRatio: isMobile.value === true ? 1 : undefined,
              indexAxis: 'y',
              plugins: {
                datalabels: datalabelsConfig,
                tooltip: {
                  callbacks:
                    props.data.type === 'doughnut'
                      ? {
                          label: function (context) {
                            return ` ${context.parsed}%`
                          },
                          title: function (context) {
                            return ` ${context[0].dataset.label} (${context[0].label})`
                          }
                        }
                      : {}
                },
                legend: {
                  display: !!(
                    props.data.type === 'doughnut' ||
                    (props.data.type === 'bar' && props.data.datasets.length > 1)
                  ),
                  position: props.data.type === 'doughnut' ? 'right' : 'bottom'
                }
              }
            }
    })

    window.addEventListener('resize', () => {
      myChart.resize()
    })
  }
})
</script>
<template>
  <h4 class="text-xl mb-4 mt-10 font-bold">
    {{ props.data.title }} {{ props.data.subtitle != '' ? `(${props.data.subtitle})` : '' }}
  </h4>
  <div v-if="props.data.type && Object.keys(props.data).length" class="chart-wrapper border-2 rounded-3xl p-8 mb-10">
    <div class="flex justify-end mb-10">
      <Button
        v-if="!isEmbed"
        v-tippy="{
          content: 'Embedcode kopiëren'
        }"
        :small="true"
        color="gray-50"
        @click="copyEmbedCode"
      >
        &lt;/>
      </Button>
    </div>
    <div>
      <canvas id="generalChart" ref="generalChart" />
    </div>
    <div>
      <p class="flex justify-between mt-10 text-gray-200 text-xs lg:text-base">
        <span>Gemaakt door <a href="https://rentbird.nl/nl">Rentbird</a></span>
        <span>Bron: <a href="https://rentbird.nl/nl/huursentiment-monitor">Huursentiment-monitor 2024</a></span>
      </p>
    </div>
  </div>
</template>
