<script setup>
import MyRentbirdCard from '@js/Components/MyRentbird/MyRentbirdCard/MyRentbirdCard.vue'
import { usePage, Link } from '@inertiajs/vue3'
import HouseCardMatchHistory from '@js/Components/HouseCards/HouseCardMatchHistory/HouseCardMatchHistory.vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'

const page = usePage()
const houses = page.props.latestHousesSentToUser
</script>
<template>
  <MyRentbirdCard :default-is-expanded="true">
    <template #title>
      {{ $trans('MatchHistoryCard.Title') }}
    </template>
    <template #title-desktop-right>
      <Link
        class="text-base text-blue-base hover:cursor-pointer hover:underline transition-all relative top-[2px]"
        :href="$getLocalizedUrl('my-rentbird.match-history')"
      >
        {{ $trans('MatchHistoryCard.MatchHistoryLink') }}
      </Link>
    </template>
    <template #default>
      <div v-if="houses.length > 0" class="grid grid-cols-1 lg:grid-cols-2 gap-3">
        <HouseCardMatchHistory
          v-for="house of houses"
          :key="house.id"
          :image="house.image"
          :name="house.name"
          :found-at-formatted="house.created_at_formatted_diff"
          :rental-agent="house.rental_agent_name"
          :city="house.city"
          :bedrooms="house.bedrooms"
          :area="house.area"
          :rent="house.rent"
          :url="house.click_track_url"
        />
      </div>

      <!-- <HouseCardMatchHistory
      v-for="item of houses"
      :key="item.house.random_id"
      class="mb-[12px] lg:w-[350px] m-auto lg:mb-[24px]"
      :name="item.house.name"
      :image="item.house.image_url"
      :url="item.house.click_track_url"
      :city="item.house.city"
      :bedrooms="item.house.bedrooms"
      :area="item.house.area"
      :rent="item.house.rent"
      :found-at-formatted="item.house.created_at_formatted_diff"
      :rental-agent="item.house.rental_agent_name"
      :show-viewed-label="true"
      :has-viewed="item.house.has_viewed"
      :is-historic="item.house.is_historic"
    /> -->
      <InfoMessageBox v-else color="blue">
        {{ $trans('MatchHistoryOverviewCard.NoHouseReceivedYetMessage') }}
      </InfoMessageBox>

      <div class="lg:hidden flex justify-end pt-[16px]">
        <a
          class="text-base text-blue-base hover:cursor-pointer hover:underline transition-all relative top-[6px]"
          :href="$getLocalizedUrl('my-rentbird.match-history')"
        >{{ $trans('MatchHistoryCard.MatchHistoryLink') }}</a>
      </div>
    </template>
  </MyRentbirdCard>
</template>
