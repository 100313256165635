import { doGetRequest, doPostRequest } from '@js/App/Api/Infra/ApiClient'
import { computed, onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { getSearchProfileDto, useSearchProfileStore } from '@js/App/Stores/SearchProfileStore'
import { isProduction } from '@js/App/Utils/Environment'
import { useB2BOrganization } from '@js/App/Api/B2B/B2B'

/**
 * Gets the expected matches per week based on a search profile.
 *
 * @param searchProfile {Ref} The search profile object "converted" to a DTO (data transfer object). The DTO is sent
 * to the API and uses a slightly different formatting than is used in the frontend. See the searchProfile store
 * for encoding / decoding the searchProfile
 * @returns {{
 *   isLoading: ComputedRef<boolean>,
 *   matchesPerWeek: Ref<UnwrapRef<number>>,
 *   hasErrors: Ref<UnwrapRef<boolean>>,
 *   errors: Ref<UnwrapRef<*[]>>
 * }}
 */
export const useMatchesPerWeek = (searchProfile) => {
  const numberOfItemsThatAreLoading = ref(0)
  const hasErrors = ref(false)
  const errors = ref([])
  const matchesPerWeek = ref(null)

  const lastGotMatchesForSearchProfile = ref(JSON.stringify({}))

  const getMatchesPerWeekFromApi = () => {
    numberOfItemsThatAreLoading.value += 1
    const searchProfileDto = getSearchProfileDto(searchProfile.value)
    const currentValue = JSON.stringify(searchProfileDto)
    setTimeout(async () => {
      if (currentValue !== JSON.stringify(searchProfileDto)) {
        numberOfItemsThatAreLoading.value -= 1
        return
      }

      lastGotMatchesForSearchProfile.value = JSON.stringify(searchProfileDto)

      let response = null
      // it is possible to get into a state where no city is selected.
      if (!searchProfileDto.city) {
        response = {
          hasErrors: false,
          json: { matches_per_week: '..' }
        }
      } else {
        response = await doPostRequest('/api/matches-per-week', searchProfileDto, isProduction())
      }

      hasErrors.value = response.hasErrors || false
      errors.value = response.errors || []
      matchesPerWeek.value = response.json?.matches_per_week

      numberOfItemsThatAreLoading.value -= 1
    }, 250)
  }

  const isLoading = computed(() => numberOfItemsThatAreLoading.value > 0)

  watch(
    () => searchProfile.value,
    async (newValue, oldValue) => {
      const dto = JSON.stringify(getSearchProfileDto(newValue))
      if (dto === lastGotMatchesForSearchProfile.value) {
        return
      }

      await getMatchesPerWeekFromApi()
    },
    { deep: true }
  )

  onMounted(async () => {
    await getMatchesPerWeekFromApi()
  })

  return {
    isLoading,
    hasErrors,
    errors,
    matchesPerWeek
  }
}

export const getMatchesPerWeekOfAllSearchProfilesOfCurrentUser = async () => {
  const response = await doGetRequest('/api/user/matches-per-week/all-search-profiles')
  const hasErrors = response.hasErrors || false
  const errors = response.errors || []
  const matchesPerWeek = response.json?.matches_per_week

  return { hasErrors, errors, matchesPerWeek }
}

export const getMatchesPerWeekOfAllSearchProfilesOfB2BUser = async (b2bUserRandomId) => {
  const { organization } = useB2BOrganization()

  const response = await doGetRequest(
    `/b2b/${organization.value}/user/${b2bUserRandomId}/matches-per-week/all-search-profiles`
  )
  const hasErrors = response.hasErrors || false
  const errors = response.errors || []
  const matchesPerWeek = response.json?.matches_per_week

  return { hasErrors, errors, matchesPerWeek }
}

/**
 * Calls the useMatchesPerWeek under the hood, but exposes nothing. Instead it retreives and stores everything in the
 * search profile store in Pinia.
 */
export const useMatchesPerWeekAndStoreResultsInPinia = () => {
  const { searchProfile, matchesPerWeek } = storeToRefs(useSearchProfileStore())

  const { isLoading, matchesPerWeek: apiMatchesPerWeek } = useMatchesPerWeek(searchProfile)

  watch([apiMatchesPerWeek, isLoading], () => {
    matchesPerWeek.value.isLoading = isLoading.value
    if (apiMatchesPerWeek.value !== null) {
      matchesPerWeek.value.matchesPerWeek = apiMatchesPerWeek.value
    }
  })
}
